import React from "react";
import CartCardGridPOS from "./CartCardGridPOS";
import { useSelector } from "react-redux";

const CartGridPOS = (props: any) => {
  const { product, pos } = useSelector((state: any) => state);

  return pos?.carts?.data?.map(
    (d: any) =>
      d?.isSubscription === "pos" && (
        <CartCardGridPOS
          data={d}
          handleSelected={props?.handleSelected}
          types={product?.attributes?.types}
        ></CartCardGridPOS>
      )
  );
};

export default CartGridPOS;
