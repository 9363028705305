import React, { useState, useRef } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Divider,
  Form,
  Input,
  notification,
  Select,
  Space,
  InputRef,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { MinusCircleOutlined } from "@ant-design/icons";
import { useMediaQuery, Button, LinearProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateCustomerAction } from "../../../store/customer/actions";
import {
  handleNextStep,
  handlePreviousStep,
} from "../../../store/layout/actions";
import PhoneInput from "react-phone-input-2";
import "./address.css";
import { relationship } from "../../../assets/data/relationship";
import { myPosActions } from "../../../store/pos";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const WitnessForm = (props: any) => {
  const [form] = Form.useForm();
  const { wareHouse, customer, auth } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const address =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address;
  const nationalId =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.nationalIDDoc;
  const momoStatement =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      ?.MomoStatementDoc &&
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      ?.MomoStatementDoc[0];
  const nationalIDBack =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.photoBackOfID;
  const nationalIdHodingId =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      ?.photoCustomerHoldingID;
  const monthlyIncome =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.monthlyIncome;
  const occupationOrBusinessType =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      ?.occupationOrBusinessType;
  const ownsSmartphone =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.ownsSmartphone;
  const mainIncomeSource =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.mainIncomeSource;
  const incomeFrequency =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.incomeFrequency;

  const [selectedCountry, setSelectedCountry] = useState("250");

  let indexBrand = 0;

  const [items, setItems] = useState([...relationship]);
  const [name, setName] = useState("");
  const inputRef = useRef<InputRef>(null);

  const addItem = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();
    setItems([...items, name || `New item ${indexBrand++}`]);
    setName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onFinish = async (values: any) => {
    dispatch(myPosActions.setCustomerSignature(null));
    const name = `${values?.firstName}  ${values?.lastName}`;

    if (!values?.witness) {
      notification.info({ message: "Add Other Witness" });
      return;
    }

    const others: Array<any> = values?.witness?.map((item: any) => {
      return {
        ...item,
        name: `${
          item?.lastName ? `${item?.name} ${item?.lastName}` : item?.name
        }`,
      };
    });
    delete values.witness;
    // Check if any of the witness relationships is "mudugudu"
    // const hasMuduguduRelationship = others?.some(
    //   (witness: any) => witness?.relationship?.toLowerCase() === "mudugudu"
    // );

    // if (!hasMuduguduRelationship) {
    //   notification.info({
    //     message: "Please make sure one of the witnesses is Mudugudu.",
    //   });
    //   return;
    // }

    if (wareHouse?.createdCart?.data?.customer?._id && address && auth?.token) {
      await updateCustomerAction(
        auth?.token,
        wareHouse?.createdCart?.data?.customer?._id,
        {
          subscriptionInfo: {
            address: address,
            witnessInfo: others,
            nationalIDDoc: nationalId,
            photoBackOfID: nationalIDBack,
            photoCustomerHoldingID: nationalIdHodingId,
            MomoStatementDoc: [momoStatement],
            monthlyIncome: monthlyIncome,
            occupationOrBusinessType: occupationOrBusinessType,
            ownsSmartphone: ownsSmartphone,
            mainIncomeSource: mainIncomeSource,
            incomeFrequency: incomeFrequency,
          },
        }
      )(dispatch);
      await handleNextStep(1)(dispatch);
    } else {
      notification.info({ message: "Missing Address Details" });
    }
  };

  const handlePrevious = async () => {
    await handlePreviousStep(1)(dispatch);
  };

  const handleCountryChange = (country: any) => {
    setSelectedCountry(country);
  };

  const NotTablet = useMediaQuery("(min-width: 1536px)");

  return (
    <div className="lg:max-2xl:h-56 h-full">
      <div className="pb-5">
        <div className="flex flex-row justify-between pb-2">
          <div>Witness Details</div>
          <div>
            <span className="text-[#5F5AFE]">Step 2</span> of 5
          </div>
        </div>
        <LinearProgress variant="determinate" value={30} />
      </div>
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        layout="vertical"
        onFinish={onFinish}
        initialValues={{}}
        style={{ maxWidth: "100%" }}
        scrollToFirstError
        className="relative"
      >
        <Form.List
          name="witness"
          {...formItemLayout}
          initialValue={
            wareHouse?.createdCart?.data?.customer?.subscriptionInfo &&
            wareHouse?.createdCart?.data?.customer?.subscriptionInfo
              ?.witnessInfo?.length !== 0
              ? [
                  ...wareHouse?.createdCart?.data?.customer?.subscriptionInfo
                    ?.witnessInfo,
                ]
              : [{}]
          }
        >
          {(fields, { add, remove }) => {
            const addButtonDisabled = fields.length >= 4;
            return (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div className="ml-5 lg:max-2xl:ml-0 lg:max-2xl:-mt-4">
                    {/* <p className="text-[#0F0F47] text-base font-normal">
                      Witness: {key + 1}
                    </p> */}
                    <div className="grid grid-cols-2 lg:max-2xl:gap-1 mt-2 lg:max-2xl:mt-0 ">
                      <Form.Item
                        {...restField}
                        name={[name, "name"]}
                        className="w-[90%]"
                        label={
                          <span className="text-[#0F0F47] text-base font-medium">
                            First Name
                          </span>
                        }
                        rules={[
                          {
                            required: false,
                            message: "Names are required!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="FirstName"
                          className="pl-4 h-[52px] w-[100%] lg:max-2xl:w-[190px] lg:max-2xl:h-[42px] bg-[#EFF0F6] border-none"
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "lastName"]}
                        className="w-[100%]"
                        label={
                          <span className="text-[#0F0F47] text-base font-medium">
                            Last Name
                          </span>
                        }
                        rules={[
                          {
                            required: false,
                            message: "Names are required!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Last Name"
                          className="pl-4 h-[52px] w-[100%] lg:max-2xl:w-[190px] lg:max-2xl:h-[42px] bg-[#EFF0F6] border-none"
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "phone"]}
                        className="w-[90%] lg:max-2xl:w-72 lg:max-2xl:-mt-2"
                        label={
                          <span className="text-[#0F0F47] text-base font-medium">
                            Phone
                          </span>
                        }
                        initialValue={props?.dataToUpdate?.phone}
                        rules={[
                          {
                            validator: (_, value) => {
                              const phoneNumberLength = value
                                ? value.replace(/\D/g, "").length
                                : 0;
                              if (selectedCountry?.slice(0, 3) === "250") {
                                if (phoneNumberLength === 12) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error(
                                    "Phone Number should be exactly 9 Digits."
                                  )
                                );
                              } else {
                                return Promise.resolve();
                              }
                            },
                          },
                        ]}
                      >
                        <PhoneInput
                          onChange={handleCountryChange}
                          country={"rw"}
                          buttonStyle={{
                            height: NotTablet ? "50px" : "35px",
                            border: "none",
                            borderRight: "3px solid #fff",
                            backgroundColor: "#EFF0F6",
                          }}
                          inputStyle={{
                            height: NotTablet ? "50px" : "35px",
                            width: "100%",
                            backgroundColor: "#EFF0F6",
                            border: "none",
                          }}
                          containerClass="phone-container"
                          placeholder="+250 780 000 000"
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "relationship"]}
                        className="w-[100%]"
                        label={
                          <span className="text-[#0F0F47] text-base font-medium">
                            RelationShip
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Relationsip is required!",
                          },
                        ]}
                      >
                        <Select
                          style={{ display: "flex", alignItems: "center" }}
                          className="h-[52px]"
                          showSearch
                          dropdownRender={(menu) => (
                            <>
                              {menu}
                              <Divider style={{ margin: "8px 0" }} />
                              <Space style={{ padding: "0 8px 4px" }}>
                                <Input
                                  placeholder="Add New"
                                  ref={inputRef}
                                  onChange={onNameChange}
                                />
                                <Button variant="contained" onClick={addItem}>
                                  <PlusOutlined />
                                </Button>
                              </Space>
                            </>
                          )}
                          options={items.map((item) => ({
                            label: item,
                            value: item,
                          }))}
                        />
                      </Form.Item>

                      {key >= 1 && (
                        <div className="flex flex-row items-start  gap-2">
                          <Form.Item>
                            <Button
                              onClick={() => remove(name)}
                              className=" shadow !m-0 justify-between !items-center text-red-500"
                            >
                              <MinusCircleOutlined className=" my-auto text-red-500" />
                            </Button>
                          </Form.Item>
                        </div>
                      )}
                    </div>
                    <Divider />
                  </div>
                ))}
                <div className="pl-6">
                  <Form.Item>
                    <Button
                      onClick={() => !addButtonDisabled && add()}
                      className="shadow !m-0 justify-between !items-center text-[#2943D6]"
                      disabled={
                        wareHouse?.createdCart?.data?.isSubscriptionSubmitted
                      }
                    >
                      <PlusOutlined />
                    </Button>
                  </Form.Item>
                </div>
              </>
            );
          }}
        </Form.List>
        <div className="flex justify-end gap-x-2 mr-24 mt-16 !h-4 box-border">
          <div className="flex items-center space-x-2 -mt-16">
            <Form.Item>
              <div className="flex items-center space-x-2">
                <LoadingButton
                  variant="outlined"
                  loading={customer?.isFetching}
                  style={{
                    width: "150px",
                  }}
                  onClick={handlePrevious}
                >
                  {`Previous`}
                </LoadingButton>

                <LoadingButton
                  type="submit"
                  variant="contained"
                  style={{
                    padding: "6px",
                    width: "80px",
                  }}
                  loading={customer?.isFetching}
                >
                  {`Next`}
                </LoadingButton>
              </div>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};
export default WitnessForm;
