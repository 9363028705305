import React, { useEffect, useState } from "react";
import { Form, Input, notification, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateCustomerAction } from "../../../store/customer/actions";
import { handleNextStep } from "../../../store/layout/actions";
import countryList from "country-list";
// @ts-ignore
import { Provinces, Districts, Sectors, Cells, Villages } from "rwanda";

const AddressDetailsForm = (props: any) => {
  const { wareHouse, auth } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedSector, setSelectedSector] = useState("");
  const [selectedCell, setSelectedCell] = useState("");
  const [selectedVillage, setSelectedVillage] = useState("");

  const [listDistrict, setListDistrict] = useState<any[]>([]);
  const [listSector, setListSector] = useState<any[]>([]);
  const [listCell, setListCell] = useState<any[]>([]);
  const [listVillage, setListVillage] = useState<any[]>([]);

  const witnessInfo =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.witnessInfo;
  const nationalId =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.nationalIDDoc;
  const momoStatement =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      ?.MomoStatementDoc &&
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      ?.MomoStatementDoc[0];
  const countryOptions = countryList.getData().map((country: any) => ({
    label: country.name,
    value: country.name,
  }));

  const provinceOptions: [{ value: string; label: string }] = Provinces().map(
    (pro: string) => ({
      label: pro,
      value: pro,
    })
  );

  const onFinish = async (values: any) => {
    if (
      !Villages(
        selectedProvince ?? "",
        selectedDistrict ?? "",
        selectedSector ?? "",
        selectedCell ?? ""
      )
    ) {
      return notification.error({ message: "Address is not well arranged!" });
    }

    if (wareHouse?.createdCart?.data?.customer?._id && auth?.token) {
      await updateCustomerAction(
        auth?.token,
        wareHouse?.createdCart?.data?.customer?._id,
        {
          subscriptionInfo: {
            address: values,
            witnessInfo: witnessInfo,
            nationalIDDoc: nationalId,
            MomoStatementDoc: [momoStatement],
          },
        }
      )(dispatch);
      await handleNextStep(1)(dispatch);
    } else {
      notification.info({ message: "Missing Personal Details" });
    }
  };

  // if Province changes
  useEffect(() => {
    try {
      setListDistrict(
        Districts(selectedProvince ?? "").map((val: string) => ({
          label: val,
          value: val,
        }))
      );
    } catch (e: any) {
      setListSector([]);
      setSelectedSector("");

      return;
    }
  }, [selectedProvince]);

  useEffect(() => {
    try {
      setListSector(
        Sectors(selectedProvince ?? "", selectedDistrict ?? "").map(
          (val: string) => ({
            label: val,
            value: val,
          })
        )
      );
    } catch (e: any) {
      setSelectedCell("");
      setListCell([]);
      return;
    }
  }, [selectedDistrict, listDistrict]);

  useEffect(() => {
    try {
      setListCell(
        Cells(
          selectedProvince ?? "",
          selectedDistrict ?? "",
          selectedSector ?? ""
        ).map((val: string) => ({
          label: val,
          value: val,
        }))
      );
    } catch (e: any) {
      setSelectedVillage("");
      setListVillage([]);
      return;
    }
  }, [listSector, selectedSector]);

  useEffect(() => {
    try {
      setListVillage([]);
      setSelectedVillage("");
      setListVillage(
        Villages(
          selectedProvince ?? "",
          selectedDistrict ?? "",
          selectedSector ?? "",
          selectedCell ?? ""
        ).map((val: string) => ({
          label: val,
          value: val,
        }))
      );
    } catch (e: any) {
      console.log(e);
    }
  }, [listCell, selectedCell]);

  useEffect(() => {
    if (wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address) {
      form.setFieldsValue(
        wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address
      );
    } else {
      form.resetFields();
    }
  }, [form, wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address]);

  return (
    <div className="rounded-lg pt-4">
      <div>
        <h1 className="text-black text-lg pl-4 pb-4 font-semibold">
          Address Details
        </h1>
      </div>
      <div className="grid grid-cols-2 space-x-4 ">
        <Form.Item
          name="country"
          className="w-[90%] pl-4"
          // initialValue={firstNameToUpdate}
          label={<span className="text-[#0F0F47] text-sm">Country</span>}
          rules={[
            {
              required: true,
              message: "Country is required!",
              whitespace: true,
            },
          ]}
        >
          <Select
            showSearch
            // defaultValue={"Rwanda"}
            className="w-[90%] h-[52px]"
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "")
                .toString()
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toString()
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toString().toLowerCase())
            }
            onChange={(value: string) => {
              setSelectedCountry(value);
              form.resetFields([
                "provence",
                "district",
                "sector",
                "cell",
                "village",
              ]);
            }}
            options={countryOptions}
          >
            <Input className="pl-4 h-[52px] w-[100%] lg:max-2xl:w-[190px] lg:max-2xl:h-[42px] bg-[#EFF0F6] border-none" />
          </Select>
        </Form.Item>
        <Form.Item
          className="w-[95%]"
          name="provence"
          label={<span className="text-[#0F0F47] text-sm">Province</span>}
          rules={[
            {
              required: true,
              message: "Province is required!",
              whitespace: true,
            },
          ]}
        >
          {selectedCountry === "Rwanda" ? (
            <Select
              showSearch
              className="w-[100%] h-[52px]"
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toString()
                  .toLowerCase()
                  .localeCompare(
                    (optionB?.label ?? "").toString().toLowerCase()
                  )
              }
              onChange={(value: any) => {
                setSelectedProvince(value);
                form.resetFields(["district", "sector", "cell", "village"]);
              }}
              options={provinceOptions}
              allowClear
            />
          ) : (
            <Input
              placeholder="Choose Province"
              className="pl-4 h-[52px] w-[100%] lg:max-2xl:w-[190px] lg:max-2xl:h-[42px] bg-[#EFF0F6] border-none"
            />
          )}
        </Form.Item>
        <Form.Item
          className="w-[90%]"
          name="district"
          label={<span className="text-[#0F0F47] text-sm">District</span>}
          rules={[
            {
              required: true,
              message: "District is required!",
              whitespace: true,
            },
          ]}
        >
          {selectedCountry === "Rwanda" ? (
            <Select
              showSearch
              className="w-[90%] h-[52px]"
              placeholder={
                <div className="flex flex-col align-center">
                  Search to Select
                </div>
              }
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toString()
                  .toLowerCase()
                  .localeCompare(
                    (optionB?.label ?? "").toString().toLowerCase()
                  )
              }
              onChange={(value: any) => {
                setSelectedDistrict(value);
                form.resetFields(["sector", "cell", "village"]);
              }}
              options={listDistrict}
              allowClear
            />
          ) : (
            <Input
              placeholder="Choose District"
              className="pl-4 h-[52px] w-[100%] lg:max-2xl:w-[190px] lg:max-2xl:h-[42px] bg-[#EFF0F6] border-none"
            />
          )}
        </Form.Item>
        <Form.Item
          className="w-[95%]"
          name="sector"
          // initialValue={props?.dataToUpdate?.name
          //   ?.slice(firstNameToUpdate?.length)
          //   ?.trim()}
          label={<span className="text-[#0F0F47] text-sm">Sector</span>}
          rules={[
            {
              required: true,
              message: "Sector is required!",
              whitespace: true,
            },
          ]}
        >
          {selectedCountry === "Rwanda" ? (
            <Select
              showSearch
              className="w-[100%] h-[52px]"
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toString()
                  .toLowerCase()
                  .localeCompare(
                    (optionB?.label ?? "").toString().toLowerCase()
                  )
              }
              onChange={(value: any) => {
                setSelectedSector(value);
                form.resetFields(["cell", "village"]);
              }}
              options={listSector}
              value={selectedSector}
              allowClear
            />
          ) : (
            <Input
              placeholder="Choose Sector"
              className="pl-4 h-[52px] w-[100%] lg:max-2xl:w-[190px] lg:max-2xl:h-[42px] bg-[#EFF0F6] border-none"
            />
          )}
        </Form.Item>
        <Form.Item
          name="cell"
          className="w-[90%]"
          label={<span className="text-[#0F0F47] text-sm">Cell</span>}
          rules={[
            {
              required: true,
              message: "Cell is required!",
              whitespace: true,
            },
          ]}
        >
          {selectedCountry === "Rwanda" ? (
            <Select
              showSearch
              className="w-[100%] h-[52px]"
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toString()
                  .toLowerCase()
                  .localeCompare(
                    (optionB?.label ?? "").toString().toLowerCase()
                  )
              }
              onChange={(value: any) => {
                setSelectedCell(value);
                form.resetFields(["village"]);
              }}
              options={listCell}
              value={selectedCell}
              allowClear
            />
          ) : (
            <Input
              placeholder="Choose Cell"
              className="pl-4 h-[52px] w-[100%] lg:max-2xl:w-[190px] lg:max-2xl:h-[42px] bg-[#EFF0F6] border-none"
            />
          )}
        </Form.Item>
        <Form.Item
          className="w-[95%]"
          name="village"
          label={<span className="text-[#0F0F47] text-sm">Village</span>}
          initialValue={props?.dataToUpdate?.email}
          rules={[
            {
              required: true,
              message: "Village is required!",
              whitespace: true,
            },
          ]}
        >
          {selectedCountry === "Rwanda" ? (
            <Select
              showSearch
              className="w-[100%] h-[52px]"
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toString()
                  .toLowerCase()
                  .localeCompare(
                    (optionB?.label ?? "").toString().toLowerCase()
                  )
              }
              onChange={(value: any) => setSelectedVillage(value)}
              options={listVillage}
              value={selectedVillage}
              allowClear
            />
          ) : (
            <Input className="pl-4 h-[52px] w-[100%] lg:max-2xl:w-[190px] lg:max-2xl:h-[42px] bg-[#EFF0F6] border-none" />
          )}
        </Form.Item>
      </div>
    </div>
  );
};

export default AddressDetailsForm;
