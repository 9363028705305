import React from "react";
import { LinearProgress } from "@mui/material";
import { IoIosArrowUp, IoIosCall } from "react-icons/io";
import POSItemSearch from "../../../pages/dashboard/POS/POSItemSearch";
import { IoPersonSharp } from "react-icons/io5";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { Button, Carousel, Form } from "antd";
import InfoIcon from "@mui/icons-material/Info";
import { useSelector } from "react-redux";
import { getActivePrice, getActiveShop } from "../../../utils/converter";
import { myWareHouseActions } from "../../../store/wareHouse";
import { useDispatch } from "react-redux";
import { getAllShopStockAction } from "../../../store/wareHouse/actions";
import SearchBar from "../../SearchBar";
import BarcodeScannerModal from "../../BarcodeScannerModal";
import { ReactComponent as SearchItemImage } from "../../../assets/images/Scan to sell Option 2.svg";

const ScanningDevices = (props: any) => {
  const [showItemSearch, setShowItemSearch] = React.useState(true);
  const { wareHouse, customer, channel, auth, appSettings } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
  const [scannedCode, setScannedCode] = React.useState<any>();
  const [newSearch, setNewSearch] = React.useState<any>(false);

  const productData = wareHouse?.searchResult;

  const toggleItemSearch = () => {
    setShowItemSearch(!showItemSearch);
  };

  const discardSearchResult = () => {
    dispatch(myWareHouseActions.setSearchResult(null));
  };

  const getCode = (code: string) => {
    setNewSearch(false);
    onFinish({ query: code });
    setScannedCode(code);
  };

  const itemPrice = getActivePrice(
    productData?.data?.product?.requestedItem?.product?.prices
  );

  const onFinish = async (values: any) => {
    auth?.token &&
      (await getAllShopStockAction(
        auth?.token,
        `?serialNumber=${values?.query}&shopId=${
          getActiveShop(auth?.user?.shop?.assigned)[
            appSettings?.selectedShopNumber
          ]?.shop?._id ?? auth?.user?.shop?.shopId
        }`
      )(dispatch));
  };

  const isFound =
    wareHouse?.searchResult?.status === 200 &&
    wareHouse?.searchResult?.data?.isInShop &&
    wareHouse?.searchResult?.data?.product?.status !== "sold";

  const handleChange = (e: any) => {
    setNewSearch(true);
  };

  return (
    <>
      <div className="flex flex-row justify-between pb-3">
        <div>Scanning Device</div>
        <div>
          <span className="text-[#5F5AFE]">Step 5</span> of 5
        </div>
      </div>
      <LinearProgress variant="determinate" value={100} />
      <div className="pt-3">
        <div
          className={`relative h-[45%] bg-white rounded-lg text-black ${
            wareHouse?.createdCart?.data?.status === "inquiry"
              ? ""
              : "pl-4 pr-4 pt-2 pb-2"
          }  leading-none box-border`}
        ></div>

        <POSItemSearch
          addToCart={props.addToCart}
          setIsNewItemAdded={props.setIsNewItemAdded}
          isNewItemAdded={props.isNewItemAdded}
        />
        {showItemSearch ? (
          <div className="flex justify-center  bg-white">
            {/* <POSItemSearch
              addToCart={props.addToCart}
              setIsNewItemAdded={props.setIsNewItemAdded}
              isNewItemAdded={props.isNewItemAdded}
            /> */}
          </div>
        ) : (
          <div>
            {/* <div className="flex flex-row border border-gray-350 pt-3 rounded-md">
              <div className="flex flex-col  h-[100%]  w-[100%] pt-6">
                <div className="flex justify-end">
                  <div className="w-[100%] rounded-md h-[350px] flex flex-row p-4">
                    <div><img src="https://res.cloudinary.com/kitech/image/upload/v1706601904/Rectangle_1381_2_u1f1iv.png" /></div>
                    <div className="space-y-2">
                      <div className="font-Poppins font-bold leading-6">Iphone 14</div>
                      <div className="space-y-4">
                        <div>
                          <span className="text-gray-400">Type:</span><span className="text-black">Smartphone</span>
                        </div>
                        <div>
                          <span className="text-gray-400">Brand: </span><span className="text-black">Apple</span>
                        </div>
                        <div>
                          <span className="text-gray-400">Capacity (ROM):</span><span className="text-black"> 128GB</span>
                        </div>
                        <div>
                          <span className="text-gray-400">Memory (RAM):</span><span className="text-black">6GB</span>
                        </div>
                        <div>
                          <span className="text-gray-400"> Extended Warranty:</span><span className="text-black">10%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="font-Poppins text-[#030229] flex justify-start px-4 font-Poppins pb-4 items-center cursor-pointer">
                  <img src="https://res.cloudinary.com/kitech/image/upload/v1706611062/mingcute_information-fill_iwwmvj.png" /><span>This device is eligible for extended warranty.</span>
                </div>
              </div>
              <div className=" flex flex-col h-[100%]  w-[100%] pt-6">
                <div className="flex justify-end">
                  <div className="w-[100%] rounded-md h-[350px] flex flex-row justify-end p-4">
                    <span className="bg-[#FFC727] h-[30px] pt-2 rounded-md pb-3 px-2 cursor-pointer ">inshop</span>
                  </div>
                </div>
                <div className="font-Poppins font-bold text-[#030229] flex justify-end px-4 font-Poppins pb-4">
                  RWF 1,225,000
                </div>
              </div>
            </div> */}
            <div className="flex flex-col justify-between h-[calc(100%-50px)]">
              {/* {!isFound && (
                <div className="flex flex-col">
                  <div className="flex justify-center gap-x-2">
                    <Form onFinish={onFinish}>
                      <Form.Item
                        name="query"
                        className="rounded-lg"
                     
                        initialValue={scannedCode}
                      >
                        <SearchBar
                          isLoading={wareHouse.isFetching}
                          scannedCode={newSearch ? "" : scannedCode}
                          onChange={handleChange}
                        />
                      </Form.Item>
                      <div className="py-1">
                        {!wareHouse?.isFetching &&
                          wareHouse?.searchResult?.status === 200 &&
                          wareHouse?.searchResult?.data?.isInShop &&
                          wareHouse?.searchResult?.data?.product?.status !==
                          "sold" && (
                            <p className="font-semibold text-green-500">
                              Product found
                            </p>
                          )}
                        {!wareHouse?.isFetching &&
                          wareHouse?.searchError?.status === 404 && (
                            <p className="font-semibold text-red-500">
                              Product not found
                            </p>
                          )}
                        {!wareHouse?.isFetching &&
                          !wareHouse?.searchResult?.data?.isInShop && (
                            <div className="font-semibold text-yellow-500">
                              {wareHouse?.searchResult?.message}
                            </div>
                          )}
                        {!wareHouse?.isFetching &&
                          wareHouse?.searchResult?.data?.isInShop &&
                          wareHouse?.searchResult?.data?.product?.status ===
                          "sold" && (
                            <div className="font-semibold text-yellow-500">
                              The product is sold
                            </div>
                          )}
                      </div>
                    </Form>
                    <BarcodeScannerModal getCode={getCode} />
                  </div>
                  <div className="flex justify-center">
                    <SearchItemImage className="h-44" />
                  </div>
                </div>
              )} */}

              {/* {isFound && (
              <div className="relative flex-1 rounded-lg border">
                <div className="flex gap-2 p-3 pb-4 30px text-[1vw]">
                  <div style={{ width: "7rem" }}>
                    <Carousel autoplay>
                      {productData?.data?.product?.requestedItem?.product?.product?.images?.map(
                        (im: any) => (
                          <div>
                            <img src={im} width={"100%"} alt="" />
                          </div>
                        )
                      )}
                    </Carousel>
                  </div>
                  <div className="flex gap-x-4">
                    <div className="flex flex-col gap-y-1">
                      <p className="text-[1.2vw] font-bold">
                        {
                          productData?.data?.product?.requestedItem?.product
                            ?.product?.model
                        }
                      </p>
                      <p>
                        {
                          productData?.data?.product?.requestedItem?.product
                            ?.product?.type
                        }
                      </p>

                      <div className="flex flex-col gap-2">
                        {productData?.data?.product?.requestedItem?.product?.specification?.map(
                          (item: string, _index: number) => (
                            <span className="text-[1vw]">
                              {item[0]}:<b>{item[1]}</b>
                            </span>
                          )
                        )}
                      </div>
                    </div>

                    <div className="border-t border-gray-200 mt-3 pt-2">
                      <p>
                        <b>Extended Warranty :</b>{" "}
                        {`${productData?.data?.product?.requestedItem?.product?.extendedWarranty}%`}
                      </p>
                      <p>
                        <b>In Shop :</b>
                        {productData?.data?.isInShop ? "Yes" : "No"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="absolute bottom-1 w-full flex gap-x-1 !box-border px-2">
                  <p className="flex items-center">
                    <InfoIcon /> This device is eligible for Extended Warranty.
                  </p>
                  <p className="flex-1 text-right text-[1.4vw] font-bold">{`RWF ${itemPrice?.toLocaleString()}`}</p>
                </div>
              </div>
            )} */}

              {/* {isFound && (
              <div className="absolute bottom-2 right-2 flex items-center gap-4">
                {!hasSubscription && isSubscripitonPage && (
                  <div className="text-yellow-500">
                    This model does not support subscription at them moment,
                    please try a different one!
                  </div>
                )}
                <div className="flex flex-wrap gap-x-2 ">
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    onClick={props?.addToCart}
                    disabled={
                      !productData?.data?.isInShop ||
                      (!hasSubscription && isSubscripitonPage)
                    }
                    // className=" !text-[.6vw]"
                    // startIcon={<AddIcon />}
                    // sx={{ minWidth: "100%" }}
                    loading={wareHouse?.isFetching}
                  >
                    Add To Cart
                  </LoadingButton>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    onClick={discardSearchResult}
                    disabled={!productData?.data?.isInShop}
                  // startIcon={<AddIcon />}
                  // sx={{ minWidth: "100%" }}
                  // loading={channel?.isFetching}
                  >
                    Discard
                  </LoadingButton>
                </div>
              </div>
            )} */}
            </div>
            {/* <div className="flex justify-end h-[100%]  w-[100%] pt-6">
              <div className="flex flex-row justify-around">
                <Button className="rounded-md pb-3 text-[#5F5AFE] border border-[#5F5AFE]"

                  onClick={discardSearchResult}
                  disabled={!productData?.data?.isInShop}
                  loading={channel?.isFetching}
                >DISCARD</Button>
                <Button className="rounded-md pb-3 text-white bg-[#5F5AFE]"
                  onClick={props?.addToCart}
                  loading={wareHouse?.isFetching}>ADD TO CART</Button>
              </div>
            </div> */}
            {/* <div className="relative flex-1 rounded-lg border w-[100%]">
       <div className="flex gap-2 p-3 pb-4 30px text-[1vw]">
         <div style={{ width: "7rem" }}>
           <Carousel autoplay>
             {productData?.data?.product?.requestedItem?.product?.product?.images?.map(
               (im: any) => (
                 <div>
                   <img src={im} width={"100%"} alt="" />
                 </div>
               )
             )}
           </Carousel>
         </div>
         <div className="flex gap-x-4">
           <div className="flex flex-col gap-y-1">
             <p className="text-[1.2vw] font-bold">
               {
                 productData?.data?.product?.requestedItem?.product
                   ?.product?.model
               }
             </p>
             <p>
               {
                 productData?.data?.product?.requestedItem?.product
                   ?.product?.type
               }
             </p>

             <div className="flex flex-col gap-2">
               {productData?.data?.product?.requestedItem?.product?.specification?.map(
                 (item: string, _index: number) => (
                   <span className="text-[1vw]">
                     {item[0]}:<b>{item[1]}</b>
                   </span>
                 )
               )}
             </div>
           </div>

           <div className="border-t border-gray-200 mt-3 pt-2">
             <p>
               <b>Extended Warranty :</b>{" "}
               {`${productData?.data?.product?.requestedItem?.product?.extendedWarranty}%`}
             </p>
             <p>
               <b>In Shop :</b>
               {productData?.data?.isInShop ? "Yes" : "No"}
             </p>
           </div>
         </div>
       </div>
       <div className="absolute bottom-1 w-full flex gap-x-1 !box-border px-2">
         <p className="flex items-center">
           <InfoIcon /> This device is eligible for Extended
           Warranty.
         </p>
         <p className="flex-1 text-right text-[1.4vw] font-bold">{`RWF ${itemPrice?.toLocaleString()}`}</p>
       </div>
     </div> */}
          </div>
        )}

        {/* <div className="flex justify-center h-[45%] bg-white pt-10">
          <div className="w-[100%] flex flex-row h-[100%] pt-4 justify-around">
            <div className="space-y-6">
              <div className="font-poppins font-medium text-base leading-6">
                PERSONAL DETAILS
              </div>
              <div className="text-gray-500 font-poppins flex flex-row items-center gap-2">
                <span><IoPersonSharp /></span><span>Cyifuzo Jean</span>
              </div>
              <div className="text-gray-500 font-poppins flex flex-row items-center gap-2">
                <span><IoIosCall /></span><span>0788000000</span>
              </div>
              <div className="text-gray-500 font-poppins flex flex-row items-center gap-2">
                <span><IoPersonSharp /></span><span>cyifuzo@samphone.co </span>
              </div>
              <div className="text-gray-500 font-poppins flex flex-row items-center gap-2">
                <span className="font-poppins">ID: </span><span>12000123456789</span>
              </div>
              <div className="text-[#5F5AFE] font-poppins cursor-pointer">
                View other contacts (2)
              </div>
              <div className="font-poppins font-medium text-base leading-6">
                ADDRESS DETAILS
              </div>
              <div className="text-gray-500 font-poppins flex flex-row items-center gap-4">
                <div>
                  <span className="font-poppins">Country : </span><span className="text-black">Rwanda</span>
                </div>
                <div>
                  <span className="font-poppins">Sector : </span><span className="text-black">Kicukiro</span>
                </div>
              </div>
              <div className="text-gray-500 font-poppins flex flex-row items-center gap-4">
                <div>
                  <span className="font-poppins">Province : </span><span className="text-black">Kigali</span>
                </div>
                <div>
                  <span className="font-poppins">Cell : </span><span className="text-black">Kicukiro</span>
                </div>
              </div>
              <div className="text-gray-500 font-poppins flex flex-row items-center gap-4">
                <div>
                  <span className="font-poppins">District : </span><span className="text-black">Rwanda</span>
                </div>
                <div>
                  <span className="font-poppins">Village : </span><span className="text-black">Kicukiro</span>
                </div>
              </div>
            </div>
            <div className="space-y-6">
              <div className="font-poppins font-medium text-base leading-6">
                PLAN DETAILS
              </div>
              <div className=" font-poppins flex flex-row items-center gap-2">
                <span className="text-gray-500">Device : </span><span className="text-black">Iphone 14</span>
              </div>
              <div className="font-poppins flex flex-row items-center gap-2">
                <span className="text-gray-500 ">Capacity(ROM) : </span><span className="text-black">128GB</span>
              </div>
              <div className=" font-poppins flex flex-row items-center gap-2">
                <span className="text-gray-500">Monthly Payment : </span><span className="text-black">30,000 RWF</span>
              </div>
              <div className=" font-poppins flex flex-row items-center gap-2">
                <span className="text-gray-500">Plan Period : </span><span className="text-black">12 Months</span>
              </div>
              <div className=" font-poppins flex flex-row items-center gap-2">
                <span className="text-gray-500">Payment Plan : </span><span className="text-black">Weekly</span>
              </div>
              <div className=" font-poppins flex flex-row items-center gap-2">
                <span className="text-gray-500">Initial Payment : </span><span className="text-black">30,000 RW</span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center cursor-pointer pb-4" onClick={toggleItemSearch}>
          <IoIosArrowDropdownCircle className={`bg-[#5F5AFE] text-white w-8 h-8 ${!showItemSearch ? "rotate-180" : " "}`} />
        </div> */}
      </div>
    </>
  );
};
export default ScanningDevices;
