import { LoadingButton } from "@mui/lab";
import { Form } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import DengerImg from "../../../assets/images/denger.png";
import BarcodeScannerModal from "../../BarcodeScannerModal";
import SearchBar from "../../SearchBar";

const ReplaceDevice = (props: any) => {
  const { posYelllow, wareHouse } = useSelector((state: any) => state);
  const productData = wareHouse?.searchResult;

  return (
    <>
      <div className="flex justify-center gap-x-2">
        <Form onFinish={props?.onFinish}>
          <Form.Item
            name="query"
            className="rounded-lg"
            // label="Enter serial number"
            initialValue={props?.scannedCode}
          >
            <SearchBar
              isLoading={wareHouse.isFetching}
              scannedCode={props?.newSearch ? "" : props?.scannedCode}
              onChange={props?.handleChange}
            />
          </Form.Item>
        </Form>
        <BarcodeScannerModal getCode={props?.getCode} />
      </div>
      <div>
        {((productData?.data?.product?.wareHouseItem?.serialNumber &&
          productData?.data?.product?.wareHouseItem?.serialNumber ===
            props?.selectedCart?.list?.at(0)?.warehouseProduct?.serialNumber) ||
          wareHouse?.searchError?.data?.status === 404) && (
          <>
            <div className="flex items-center mx-auto justify-center pb-4">
              <img src={DengerImg} alt="" className="w-10 h-10" />
            </div>
            <h1 className="font-medium text-[17px] leading-relaxed">
              The device scanned with the IMEI{" "}
              <span className="text-red-500">
                {productData?.data?.product?.wareHouseItem?.serialNumber ??
                  "<<Not found>>"}
              </span>{" "}
              matches the one previously requested, which has the IMEI{" "}
              <span className="text-green-500">
                {
                  props?.selectedCart?.list?.at(0)?.warehouseProduct
                    ?.serialNumber
                }
                .
              </span>{" "}
              Please scan the correct device and proceed
            </h1>
          </>
        )}

        {productData?.data?.product?.wareHouseItem?.serialNumber !==
          props?.selectedCart?.list?.at(0)?.warehouseProduct?.serialNumber &&
          productData?.data?.product?.wareHouseItem?.serialNumber && (
            <>
              <div>
                <div className="grid grid-cols-2 gap-x-10 font-extrabold text-[17px] leading-relaxed pb-4">
                  <h1>Device Found Successfully</h1>
                  <h1>Customer</h1>
                </div>
                <div className="grid grid-cols-2 gap-x-10">
                  <div className="pb-6">
                    <p className="font-medium text-[16px] leading-relaxed">
                      Model:{" "}
                      <span className="font-medium text-[14px] leading-relaxed pb-4 pl-3">
                        {
                          productData?.data?.product?.requestedItem?.product
                            ?.product?.model
                        }
                      </span>
                    </p>
                    <p className="font-medium text-[16px] leading-relaxed">
                      Brand:{" "}
                      <span className="font-medium text-[14px] leading-relaxed pb-4 pl-4">
                        {
                          productData?.data?.product?.requestedItem?.product
                            ?.product?.brand
                        }
                      </span>
                    </p>
                    <p className="font-medium text-[16px] leading-relaxed">
                      IMEI:{" "}
                      <span className="font-medium text-[14px] leading-relaxed pb-4 pl-7">
                        {
                          productData?.data?.product?.wareHouseItem
                            ?.serialNumber
                        }
                      </span>
                    </p>
                  </div>
                  <div className="pb-6 capitalize">
                    <p className="font-medium text-[16px] leading-relaxed">
                      Names:{" "}
                      <span className="font-medium text-[14px] leading-relaxed pb-4 pl-1">
                        {props?.selectedCart?.customer?.name}
                      </span>
                    </p>
                    <p className="font-medium text-[16px] leading-relaxed">
                      Phone:{" "}
                      <span className="font-medium text-[14px] leading-relaxed pb-4 pl-2">
                        {`+${props?.selectedCart?.customer?.phone}`}
                      </span>
                    </p>
                    <p className="font-medium text-[16px] leading-relaxed">
                      ID:{" "}
                      <span className="font-medium text-[14px] leading-relaxed pb-4 pl-10">
                        {props?.selectedCart?.customer?.nationalId}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        <div className="flex space-x-4 pt-4">
          <LoadingButton
            variant="outlined"
            style={{
              width: "150px",
            }}
            onClick={props?.handleCancelNextModel}
          >
            {`Back`}
          </LoadingButton>
          {productData?.data?.product?.wareHouseItem?.serialNumber !==
            props?.selectedCart?.list?.at(0)?.warehouseProduct?.serialNumber &&
            productData?.data?.product?.wareHouseItem?.serialNumber && (
              <LoadingButton
                loading={posYelllow?.isFetching || wareHouse?.isFetching}
                variant="contained"
                style={{
                  width: "150px",
                }}
                onClick={props?.handleReplaceDevice}
              >
                {wareHouse?.isReplaced ? "Submit" : "Next"}
              </LoadingButton>
            )}
        </div>
      </div>
    </>
  );
};

export default ReplaceDevice;
