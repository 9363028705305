import React, { useEffect, useState } from "react";
import { Steps, notification } from "antd";
import InsertSimCard from "./InsertSimCard";
import SetUpDevice from "./SetUpDevice";
import VaultEnabled from "./VaultEnabled";
import { LoadingButton } from "@mui/lab";
import {
  getShopStockCartAction,
  updateShopStockCartDetailsAction,
} from "../../../store/wareHouse/actions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { myWareHouseActions } from "../../../store/wareHouse";
import { makePaymentAction } from "../../../store/posYellow/actions";
import { getActiveShop } from "../../../utils/converter";

const LockingSteps = (props: any) => {
  const { auth, wareHouse, posYelllow, pos, appSettings, shop } = useSelector(
    (state: any) => state
  );
  const [current, setCurrent] = useState(
    props?.selectedCart?.yellowStatus?.toLowerCase() === "pending_deposit"
      ? 2
      : 0
  );
  const [accept, setAccept] = useState("");
  const [comment, setComment] = useState(null);

  const shopName =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?.name || shop?.myShop?.name;

  const dispatch = useDispatch();

  const steps = [
    {
      title: "",
      content: <InsertSimCard />,
    },
    {
      title: "",
      content: <SetUpDevice />,
    },
    {
      title: "",
      content: (
        <VaultEnabled
          setAccept={setAccept}
          accept={accept}
          setComment={setComment}
        />
      ),
    },
  ];

  const next = () => {
    setCurrent(current + 1);
  };

  // const skip = () => {
  //   setCurrent(current + (2 - current));
  // };

  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const contentStyle: React.CSSProperties = {
    textAlign: "center",
    backgroundColor: "white",
    marginTop: 6,
  };

  useEffect(() => {
    if (accept === "Yes" && props?.selectedCart?._id) {
      getShopStockCartAction(auth?.token, props?.selectedCart?._id)(dispatch);
    } else {
      dispatch(myWareHouseActions.setCreatedCart(null));
    }
  }, [accept, dispatch]);

  const handleSubmitPayment = async () => {
    if (props?.selectedCart?._id) {
      const res = await makePaymentAction(
        auth?.token,
        {},
        props?.selectedCart?._id
      )(dispatch);
      if (res) {
        await updateShopStockCartDetailsAction(
          auth?.token,
          props?.selectedCart?._id,
          {
            hasIssue: false,
            issues: {
              isReplacementNeed: false,
            },
          }
        )(dispatch);
        await props?.onCancel();
      }
    }
  };

  const handleSubmitIssues = async () => {
    if (comment === null || comment === "") {
      return notification.error({
        message: "Please explain the device's issue",
      });
    }
    await updateShopStockCartDetailsAction(
      auth?.token,
      props?.selectedCart?._id,
      {
        hasIssue: true,
        comment: comment,
        isNotify: true,
        notification: {
          action: `Yellow financing, device with issues`,
          role: ["admin", "dev", "sales-supervisor", "technician"],
          message: `The device with the IMEI ${
            props?.selectedCart?.list?.at(0)?.warehouseProduct?.serialNumber
          } has this issues: ${comment} from ${shopName} Shop`,
          title: `Yellow financing, device with issues`,
        },
      }
    )(dispatch);
    notification.success({
      message: "Submitted successfully",
      description: "Support Team will get back to you as soon as possible",
    });
    props?.onCancel();
  };

  return (
    <>
      <Steps current={current} items={items} />
      <div style={contentStyle}>{steps[current].content}</div>
      <div className="flex items-center justify-center mx-auto pt-12">
        {current > 0 && (
          <LoadingButton
            loading={wareHouse?.isFetching}
            variant="outlined"
            style={{
              width: "100px",
              marginRight: "16px",
              height: "40px",
            }}
            onClick={() => prev()}
          >
            {`Previous`}
          </LoadingButton>
        )}
        {current < steps.length - 1 && (
          <LoadingButton
            variant="contained"
            style={{
              width: "150px",
              height: "40px",
            }}
            onClick={() => next()}
          >
            {`Next`}
          </LoadingButton>
        )}
        {wareHouse?.createdCart?.data?.yellowStatus?.toLowerCase() ===
          "pending_ebm_upload" && (
          <LoadingButton
            variant="outlined"
            style={{
              width: "150px",
              height: "40px",
              marginRight: "20px",
            }}
            onClick={() => props?.onCancel()}
          >
            Back
          </LoadingButton>
        )}
        {accept === "" || accept === "Yes" ? (
          <>
            {current === steps.length - 1 && (
              <LoadingButton
                disabled={
                  wareHouse?.createdCart?.data?.yellowStatus?.toLowerCase() !==
                  "pending_deposit"
                }
                variant="outlined"
                style={{
                  width: "150px",
                  height: "40px",
                }}
                loading={posYelllow?.isFetching || wareHouse?.isFetching}
                onClick={handleSubmitPayment}
              >
                {`Make Payment`}
              </LoadingButton>
            )}
          </>
        ) : (
          <>
            {current === steps.length - 1 && (
              <LoadingButton
                variant="outlined"
                style={{
                  width: "150px",
                  height: "40px",
                }}
                loading={wareHouse?.isFetching || pos?.isFetching}
                onClick={handleSubmitIssues}
              >
                {`Submit`}
              </LoadingButton>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default LockingSteps;
