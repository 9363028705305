import { useState } from "react";
import { Stack } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { Badge, Carousel, Switch } from "antd";
import ProductDetailsModal from "../../Modals/ProductDetailsModal";
import UpdateProduct from "../../forms/NewProductForm";
import DeleteModal from "../../Modals/DeleteModal";
import {
  getShopStockCartAction,
  removeCartItemAction,
  updateShopStockCartItemDetailsAction,
  updateWareHouseDetailsAction,
} from "../../../store/wareHouse/actions";
import { useParams } from "react-router-dom";

const Component = (props: any) => {
  const { auth, wareHouse } = useSelector((state: any) => state);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { cartId } = useParams();
  const [activateData, setDataActivated] = useState(
    wareHouse?.createdCart?.data?.list?.at(0)?.warehouseProduct
      ?.dataActivated ?? false
  );
  const [isNewSimCard, setIsNewSimCard] = useState(
    wareHouse?.createdCart?.data?.list?.at(0)?.warehouseProduct?.isNewSimcard ??
      false
  );

  // console.log(
  //   "hereeee",
  //   wareHouse?.createdCart?.data?.list?.at(0)?.warehouseProduct?.dataActivated,
  //   wareHouse?.createdCart?.data?.list?.at(0)?.warehouseProduct?.isNewSimcard
  // );

  const [checked, setChecked] = useState(
    props?.data?.payment?.extendedWarranty
  );
  const extendedWarranty =
    props?.data?.shopStockItem?.requestedItem?.product?.extendedWarranty;

  const itemPrice = props?.data?.payment?.amount;

  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();

  const handleClickDelete = async () => {
    setVisible(true);
  };

  const handleOnClickViewIcon = (e: any) => {
    e.preventDefault();
    // setisEdit(false);
    setIsModalOpen(true);
  };

  const handleDeleteModalCancels = () => {
    setVisible(false);
  };
  const handleOnCancel = () => {
    setIsModalOpen(false);
  };

  const handleSwitchChange = async (checked: boolean) => {
    setChecked(checked);

    auth?.token &&
      updateShopStockCartItemDetailsAction(auth?.token, props?.data?._id, {
        payment: {
          ...props?.data?.payment,
          extendedWarranty: checked
            ? props?.data?.shopStockItem?.requestedItem?.product
                ?.extendedWarranty
            : 0,
        },
      })(dispatch);
  };

  const handleSwitchDataActivated = async (checked: boolean) => {
    setDataActivated(checked);
    auth?.token &&
      (await updateWareHouseDetailsAction(
        auth?.token,
        props?.data?.warehouseProduct?._id,
        {
          dataActivated: checked,
        }
      )(dispatch));

    auth?.token &&
      (await getShopStockCartAction(auth?.token, cartId)(dispatch));
  };

  const handleConfirmNewSim = async (checked: boolean) => {
    setIsNewSimCard(checked);
    if (auth?.token) {
      await updateWareHouseDetailsAction(
        auth?.token,
        props?.data?.warehouseProduct?._id,
        {
          isNewSimcard: checked,
        }
      )(dispatch);
      await getShopStockCartAction(auth?.token, cartId)(dispatch);
    }
  };

  const deleteProduct = async () => {
    if (auth?.token) {
      await removeCartItemAction(auth?.token, props?.data?._id)(dispatch);
      await getShopStockCartAction(auth?.token, cartId)(dispatch);
    }
  };

  return (
    <>
      <div className="relative w-full h-fit max-w-[584px] xbg-white text-black border pb-2 pt-4">
        <p className="absolute top-4 right-4 text-[#030229] font-medium text-base">{`RWF ${(
          itemPrice +
          (props?.data?.payment?.amount *
            props?.data?.payment?.extendedWarranty) /
            100
        )?.toLocaleString()}`}</p>
        <div className="flex w-fit">
          <div style={{ width: "4rem" }}>
            <Carousel autoplay>
              {props?.data?.shopStockItem?.requestedItem?.product?.product?.images?.map(
                (im: any) => (
                  <div>
                    <img src={im} width={"100%"} alt="" />
                  </div>
                )
              )}
            </Carousel>
          </div>
          <div className="flex flex-col flex-wrap pl-2 gap-1">
            <p className="text-[#030229] text-base font-medium">
              {
                props?.data?.shopStockItem?.requestedItem?.product?.product
                  ?.model
              }
            </p>
            <div className="mt-2">
              <p className="text-[#030229] text-sm font-normal">
                <span className="font-medium">IMEI:</span>
                <span className="pl-1">
                  {" "}
                  {props?.data?.warehouseProduct?.serialNumber}
                </span>
              </p>
            </div>

            <div className="flex flex-col gap-2">
              {props?.data?.shopStockItem?.requestedItem?.product?.specification?.map(
                (data: any, index: number) => {
                  const regex = /\((.*?)\)/;
                  const match = data[0].match(regex);

                  if (match) {
                    const stringFromBracket = match[1];

                    if (
                      !["type", "brand"].includes(
                        stringFromBracket.toLowerCase()
                      )
                    ) {
                      return (
                        <div
                          className="flex text-[#030229] text-sm font-normal"
                          key={index}
                        >
                          <p>
                            <span>{`${stringFromBracket}: `}</span>
                          </p>
                          <p className="pl-1">{data[1]}</p>
                        </div>
                      );
                    }
                  }

                  return null;
                }
              )}
              {extendedWarranty && (
                <div className="flex gap-x-2">
                  <p className="text-[#030229] text-base font-normal">{`Extended wallanty (${extendedWarranty}%): `}</p>
                  <Switch
                    size="small"
                    checked={checked}
                    className="shadow"
                    onChange={handleSwitchChange}
                  />
                </div>
              )}
            </div>
            <div className="flex gap-x-2">
              <p className="text-[#030229] text-base font-normal">
                Data Activated:
              </p>
              <Switch
                size="small"
                checked={activateData}
                className="shadow"
                onChange={handleSwitchDataActivated}
              />
            </div>
            <div className="flex gap-x-2">
              <p className="text-[#030229] text-base font-normal">
                New SIM Card:
              </p>
              <Switch
                size="small"
                checked={isNewSimCard}
                className="shadow"
                onChange={handleConfirmNewSim}
              />
            </div>
          </div>
        </div>
        {!wareHouse?.createdCart?.data?.isSubscriptionSubmitted && (
          <div className="absolute bottom-1 right-2 flex justify-end items-center w-fit py-2 space-x-2">
            <button
              aria-label="delete"
              className="text-gray-400"
              onClick={handleClickDelete}
            >
              <DeleteIcon fontSize="large" className="h-2 text-red-500" />
            </button>
          </div>
        )}
      </div>

      {/* product modal here */}

      <ProductDetailsModal
        component={
          <UpdateProduct
            dataToUpdate={props?.data}
            action={"update"}
            onCancel={handleOnCancel}
          />
        }
        isModalOpen={isModalOpen}
        handleOnClickViewIcon={handleOnClickViewIcon}
        handleOnCancel={handleOnCancel}
      />

      <DeleteModal
        visible={visible}
        onOk={deleteProduct}
        isLoading={wareHouse.isFetching}
        onCancel={handleDeleteModalCancels}
        itemName="Product"
      />
    </>
  );
};

export default Component;
