import React from "react";
import ConnectDevices from "../../../assets/images/connectDevice.png";

const SetUpDevice = () => {
  return (
    <div className="flex items-center flex-col pt-10">
      <img src={ConnectDevices} alt="" className="w-96" />
      <div className="pt-10">
        <h1 className="capitalize font-medium text-[20px] leading-relaxed">
          Setting Up Device
        </h1>
        <p className="capitalize font-medium text-[14px] leading-relaxed w-72">
          Press start button, connect device to internet network for setup (use
          sim network)
        </p>
      </div>
    </div>
  );
};

export default SetUpDevice;
