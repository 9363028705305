import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
// import { Steps } from "antd";
import { useSelector, useDispatch } from "react-redux";
// import WitnessForm from "./subscriptionCustomerForms/WitnessForm";
import PersonnelDetailsForm from "./subscriptionCustomerForms/PersonnelDetailsForm";

import { handleNextStep } from "../../store/layout/actions";
// import { LinearProgress } from "@mui/material";
import ConsentAgreementPage from "./subscriptionCustomerForms/consentAgreement";
import CheckingEligibility from "./subscriptionCustomerForms/checkingEligibility";
import MatchingDevices from "./subscriptionCustomerForms/matchingDevices";
import UploadDocs from "./subscriptionCustomerForms/UploadDocs";
import ScanningDevice from "./subscriptionCustomerForms/ScanningDevice";
import POSItemSearch from "../../pages/dashboard/POS/POSItemSearch";
import WitnessForm from "./subscriptionCustomerForms/WitnessForm";
import AddressDetailsForm from "./subscriptionCustomerForms/AddressDetailsForm";

const NewCustomerWithSubscription = (props: any) => {
  const dispatch = useDispatch();
  const { layout, wareHouse } = useSelector((state: any) => state);
  const [current, setCurrent] = useState(layout?.isNext);

  const steps = [
    {
      title: "Personnel Details",
      content: <PersonnelDetailsForm removeCustomer={props?.onCancel} />,
    },
    {
      title: "Witness Info",
      content: <WitnessForm removeCustomer={props?.onCancel} />,
    },
    {
      title: "Address",
      content: <ConsentAgreementPage removeCustomer={props?.onCancel} />,
    },
    // {
    //   title: "Checking Eligibility",
    //   content: <CheckingEligibility removeCustomer={props?.onCancel} />,
    // },
    // {
    //   title: "Device Selection",
    //   content: <MatchingDevices removeCustomer={props?.onCancel} />,
    // },
    {
      title: "Documents",
      content: <UploadDocs removeCustomer={props?.onCancel} />,
    },
    {
      title: "Scanning Devices",
      content: (
        <ScanningDevice
          addToCart={props.addToCart}
          setIsNewItemAdded={props.setIsNewItemAdded}
          isNewItemAdded={props.isNewItemAdded}
        />
      ),
    },
    // {
    //   title: "Witness Info",
    //   content: <WitnessForm removeCustomer={props?.onCancel} />,
    // },
    // {
    //   title: "Witness Info",
    //   content: <WitnessForm removeCustomer={props?.onCancel} />,
    // },
    // {
    //   title: "Documents",
    //   content: <UploadDocs removeCustomer={props?.onCancel} />,
    // },
    // {
    //   title: "Confirm",
    //   content: <ConfirmPage removeCustomer={props?.onCancel} />,
    // },
  ];

  // const items = steps?.map((item) => ({ key: item.title, title: item.title }));

  // const contentStyle: React.CSSProperties = {
  //   height: "100vh",
  //   marginTop: 2,
  // };

  // console.log(steps.length, "leng");

  useEffect(() => {
    setCurrent(layout?.isNext);
  }, [layout?.isNext]);

  useEffect(() => {
    wareHouse?.createdCart?.data?.isSubscriptionSubmitted &&
      handleNextStep(3)(dispatch);
  }, [dispatch, wareHouse?.createdCart?.data?.isSubscriptionSubmitted]);

  return <div>{steps[current]?.content}</div>;
};

export default NewCustomerWithSubscription;
