import React, { useEffect, useState, useRef } from "react";
import { Form, Input, Modal, notification, message, Upload } from "antd";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import UploadComponent from "../../../components/forms/Upload";
import { updateCustomerAction } from "../../../store/customer/actions";
import {
  PlusOutlined,
  MinusCircleOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import {
  handleNextStep,
  handlePreviousStep,
  handleUploadDiffDocsAction,
} from "../../../store/layout/actions";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Webcam, { WebcamProps } from "react-webcam";
import {
  customerProfileAction,
  nationalIdBackAction,
  nationalIdPersonWithIdAction,
} from "../../../store/pos/actions";
// import { url } from "inspector";
import { myPosActions } from "../../../store/pos";
import { LinearProgress } from "@mui/material";
import type { UploadProps } from "antd";
import { SERVER_URL } from "../../../utils/constants";

const { Dragger } = Upload;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const UploadDocs = (props: any) => {
  const { wareHouse, customer, auth, pos } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [nationalIDDoc, setNationalIDDoc] = useState<any | null>(null);
  // const [MomoStatementDoc, setMomoStatementDoc] = useState<any>([]);
  const [moreDocuments, setMoreDocuments] = useState([{ document: "" }]);
  const [showOtherDocument, setShowOtherDocument] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const webcamRef = useRef<Webcam | null>(null);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [hideButton, setHideButton] = useState(false);
  const [choosenDoc, setChoosenDoc] = useState("");

  const [nationalIDBackSide, setNationalIDBackSide] = useState<any | null>(
    null
  );
  const [nationalClientwithId, setNationalClientwithId] = useState<any | null>(
    null
  );

  const address =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address;
  const witnessInfo =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.witnessInfo;
  const nationalId =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.nationalIDDoc;
  const momoStatement =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      ?.MomoStatementDoc &&
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      ?.MomoStatementDoc[0];
  const nationalIDBack =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.photoBackOfID;
  const nationalIdHodingId =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      ?.photoCustomerHoldingID;
  const monthlyIncome =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.monthlyIncome;
  const occupationOrBusinessType =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      ?.occupationOrBusinessType;
  const ownsSmartphone =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.ownsSmartphone;
  const mainIncomeSource =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.mainIncomeSource;
  const incomeFrequency =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.incomeFrequency;

  const onFinish = async (values: any) => {
    if (
      (nationalIDDoc === null && !nationalId) ||
      (nationalIDBackSide === null && !nationalIDBack) ||
      (nationalClientwithId === null && !nationalIdHodingId)
    ) {
      return notification.error({ message: "Please upload all documents" });
    }
    if (wareHouse?.createdCart?.data?.customer?._id) {
      await updateCustomerAction(
        auth?.token,
        wareHouse?.createdCart?.data?.customer?._id,
        {
          subscriptionInfo: {
            address: address,
            witnessInfo: witnessInfo,
            nationalIDDoc: nationalIDDoc
              ? pos?.customerProfile?.data?.secure_url
              : nationalId,
            photoBackOfID: nationalIDBackSide
              ? pos?.nationIdBack?.data?.secure_url
              : nationalIDBack,
            photoCustomerHoldingID: nationalClientwithId
              ? pos?.nationIpWithPerson?.data?.secure_url
              : nationalIdHodingId,
            monthlyIncome: monthlyIncome,
            occupationOrBusinessType: occupationOrBusinessType,
            ownsSmartphone: ownsSmartphone,
            mainIncomeSource: mainIncomeSource,
            incomeFrequency: incomeFrequency,
          },
        }
      )(dispatch);
      await dispatch(myPosActions.setCustomerProfile(null));
      await handleNextStep(3)(dispatch);
    } else {
      notification.info({ message: "Missing Witness Details" });
      await handleNextStep(3)(dispatch);
    }
  };

  useEffect(() => {
    if (wareHouse?.createdCart?.data?.customer?.subscriptionInfo) {
      form.setFieldsValue(
        wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      );
    } else {
      form.resetFields();
    }
  }, [form, wareHouse?.createdCart?.data?.customer?.subscriptionInfo]);

  const initialData = {
    nationalIDDoc: nationalId,
    MomoStatementDoc: momoStatement,
  };

  const handlePrevious = async () => {
    await handlePreviousStep(3)(dispatch);
  };

  // const handleAddDocument = () => {
  //   setShowOtherDocument(true);
  //   if (moreDocuments.length === 0) {
  //     setMoreDocuments([...moreDocuments, { document: "" }]);
  //   }
  // };
  // const handleRemoveDocument = (index: number) => {
  //   const lists = [...moreDocuments];
  //   lists.splice(index, 1);
  //   setMoreDocuments(lists);
  // };

  useEffect(() => {
    moreDocuments?.length === 0 && setShowOtherDocument(false);
    showOtherDocument === true && setHideButton(true);
  }, [moreDocuments]);

  const videoConstraints: WebcamProps["videoConstraints"] = {
    facingMode: "user",
  };

  const openCamera = async (values: any) => {
    setChoosenDoc(values);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: videoConstraints,
      });
      setMediaStream(stream);
      setIsCameraOpen(true);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const closeCamera = () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop());
    }
    setIsCameraOpen(false);
  };

  const captureImage = async () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (choosenDoc === "front") {
        setNationalIDDoc(imageSrc);
        if (imageSrc) {
          customerProfileAction(auth?.token, {
            image: imageSrc,
          })(dispatch);
        }
      } else if (choosenDoc === "back") {
        setNationalIDBackSide(imageSrc);
        if (imageSrc) {
          nationalIdBackAction(auth?.token, {
            image: imageSrc,
          })(dispatch);
        }
      } else if (choosenDoc === "personWithId") {
        setNationalClientwithId(imageSrc);
        if (imageSrc) {
          nationalIdPersonWithIdAction(auth?.token, {
            image: imageSrc,
          })(dispatch);
        }
      }
      setIsCameraOpen(false);
      closeCamera();
    }
  };

  useEffect(() => {
    !isCameraOpen && setIsModalOpen(false);
  }, [isCameraOpen]);

  useEffect(() => {
    !isModalOpen && setMediaStream(null);
  }, [isModalOpen]);

  useEffect(() => {
    dispatch(myPosActions.setCustomerSignature(null));
  }, []);

  const beforeUpload = () => {
    return false;
  };

  return (
    <>
      <div className="flex flex-row justify-between pb-4">
        <div>Documents</div>
        <div>
          <span className="text-[#5F5AFE]">Step 4 </span> of 5
        </div>
      </div>
      <LinearProgress variant="determinate" value={80} />

      <Form
        {...formItemLayout}
        form={form}
        name="register"
        layout="vertical"
        onFinish={onFinish}
        initialValues={initialData || {}}
        style={{ maxWidth: "100%" }}
        scrollToFirstError
        className="flex justify-start  flex-col"
      >
        <div className="p-4">
          <div className="flex-1 rounded-lg space-y-2">
            <Form.Item
              name="CustomerProfile"
              className=" lg:max-2xl:h-24 lg:max-2xl:-mt-1"
              rules={[
                {
                  required:
                    nationalId || pos?.customerProfile?.data?.secure_url
                      ? false
                      : true,
                  message: "National ID is required",
                },
              ]}
            >
              <div className="flex flex-col 2xl:flex-row gap-4 pt-10">
                <div onClick={() => openCamera("front")}>
                  <Dragger
                    openFileDialogOnClick={false}
                    beforeUpload={beforeUpload}
                    showUploadList={false}
                    style={{ width: 350 }}
                  >
                    {nationalIDDoc || nationalId ? (
                      <img
                        src={
                          pos?.customerProfile?.data?.secure_url || nationalId
                        }
                        alt="Captured"
                        className="h-[150px] flex mx-auto"
                      />
                    ) : (
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                    )}
                    <p className="ant-upload-text font-bold w-80">
                      National ID Front Side
                    </p>
                  </Dragger>
                </div>
                <div onClick={() => openCamera("back")}>
                  <Dragger
                    openFileDialogOnClick={false}
                    beforeUpload={beforeUpload}
                    showUploadList={false}
                    style={{ width: 350 }}
                  >
                    {nationalIDBackSide || nationalIDBack ? (
                      <img
                        src={
                          pos?.nationIdBack?.data?.secure_url || nationalIDBack
                        }
                        alt="Captured"
                        className="h-[150px] flex mx-auto"
                      />
                    ) : (
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                    )}
                    <p className="ant-upload-text font-bold w-80">
                      National ID Back Side
                    </p>
                  </Dragger>
                </div>
                <div onClick={() => openCamera("personWithId")}>
                  <Dragger
                    openFileDialogOnClick={false}
                    beforeUpload={beforeUpload}
                    showUploadList={false}
                    style={{ width: 350 }}
                  >
                    {nationalClientwithId || nationalIdHodingId ? (
                      <img
                        src={
                          pos?.nationIpWithPerson?.data?.secure_url ||
                          nationalIdHodingId
                        }
                        alt="Captured"
                        className="h-[150px] flex mx-auto"
                      />
                    ) : (
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                    )}
                    <p className="ant-upload-text font-bold w-80">
                      Client Hoding National ID
                    </p>
                  </Dragger>
                </div>
              </div>
            </Form.Item>
          </div>
          <div className="pt-[50px] flex justify-end space-x-4">
            <LoadingButton
              variant="outlined"
              style={{
                width: "150px",
              }}
              onClick={handlePrevious}
            >
              {`Previous`}
            </LoadingButton>
            <LoadingButton
              type="submit"
              variant="contained"
              style={{
                padding: "7px",
                width: "14%",
              }}
              loading={customer?.isFetching}
            >
              {"Next"}
            </LoadingButton>
          </div>
        </div>
      </Form>
      <Modal title="National ID" open={isModalOpen} footer={null}>
        {isCameraOpen && (
          <>
            <div className="camera-container">
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
              />
            </div>
            <div className="  items-center justify-between pt-2">
              <LoadingButton
                variant="contained"
                style={{
                  padding: "4px",
                  width: "26px",
                  borderRadius: "2px",
                }}
                onClick={captureImage}
              >
                Save
              </LoadingButton>
              <LoadingButton
                variant="outlined"
                style={{
                  padding: "4px",
                  width: "26px",
                  borderColor: "red",
                  backgroundColor: "red",
                  color: "white",
                }}
                onClick={closeCamera}
              >
                Close
              </LoadingButton>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default UploadDocs;
