import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router";
import { createShopStockCartAction } from "../../../store/wareHouse/actions";
import { LoadingButton } from "@mui/lab";
import CartGridPOS from "../../../components/grids/POS/sale/CartGridPOS";
import { getAllCartsAction } from "../../../store/pos/actions";
import { getActiveShop, handleClearAllState } from "../../../utils/converter";
import { myWareHouseActions } from "../../../store/wareHouse";
import { myCustomerActions } from "../../../store/customer";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";
import { myLayoutActions } from "../../../store/layout";
import { Spin } from "antd";
import { FilterPosTabsButton } from "../../../components/buttons/FilterTabsButton";
import CartGridPOSWithSub from "../../../components/grids/POS/sale/CartGridPOSWithSub";

const POSSale = () => {
  const { auth, wareHouse, appSettings, pos } = useSelector(
    (state: any) => state
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isPOS, setIsPOS] = useState("pos");

  const handleCreateSale = async () => {
    auth?.token &&
      (await createShopStockCartAction(auth?.token, {
        shop:
          getActiveShop(auth?.user?.shop?.assigned)[
            appSettings?.selectedShopNumber
          ]?.shop?._id ?? auth?.user?.shop?.shopId,
      })(dispatch));
    dispatch(myCustomerActions.setSelected(null));
    dispatch(myLayoutActions.setIsNext(0));
  };

  React.useEffect(() => {
    const activeShop = getActiveShop(auth?.user?.shop?.assigned);
    auth?.token &&
      getAllCartsAction(
        auth?.token,
        `?shop=${
          activeShop[appSettings?.selectedShopNumber]?.shop?._id ??
          auth?.user?.shop?.shopId
        }&status[]=pending&status[]=rejected&status[]=accepted&status[]=requested&status[]=inquiry&status[]=review`
      )(dispatch);
  }, [
    appSettings?.selectedShopNumber,
    auth?.token,
    auth?.user?.shop?.assigned,
    auth?.user?.shop?.shopId,
    dispatch,
  ]);

  React.useEffect(() => {
    if (wareHouse?.createdCart?.data?._id) {
      if (wareHouse?.isCartCreated) {
        navigate(`${wareHouse?.createdCart?.data?._id}`);
      }
    }
  }, [navigate, wareHouse?.createdCart?.data?._id, wareHouse?.isCartCreated]);

  const clearActions = [
    myCustomerActions.setAll,
    myCustomerActions.setAttributes,
    myCustomerActions.setQuery,
    myCustomerActions.setNew,
    myCustomerActions.setUpdated,
    myCustomerActions.setSelected,
    myCustomerActions.setSearchResult,
    myCustomerActions.setPurchased,
    myCustomerActions.setCustomerRepair,
  ];

  React.useEffect(() => {
    dispatch(myWareHouseActions.setCreatedCart(null));
    handleClearAllState(dispatch, clearActions);
  }, [dispatch]);

  return (
    <div className="text-black">
      <div className="flex justify-end rounded-md pl-4 pr-4 pt-2 pb-2 bg-white">
        <div>
          <LoadingButton
            variant="contained"
            startIcon={<AddCircleIcon />}
            onClick={handleCreateSale}
            sx={{ minWidth: 250 }}
            loading={wareHouse?.isFetching}
          >
            Create Sale
          </LoadingButton>
        </div>
      </div>
      <FilterPosTabsButton setIsPOS={setIsPOS} />

      <ScrollableFrame hidePagination>
        {pos?.isFetching ? (
          <div className="flex flex-col items-center justify-center mx-auto mt-32">
            <span>
              {" "}
              <Spin spinning />
            </span>
            <span className="ml-3">Loading...</span>
          </div>
        ) : (
          <>
            {isPOS === "pos" ? (
              <div className="flex flex-wrap flex-col gap-4 justify-start items-start py-2 rounded-lg">
                <div className="flex flex-wrap gap-2">{<CartGridPOS />}</div>
              </div>
            ) : (
              <div className="flex flex-wrap flex-col gap-4 justify-start items-start py-2 rounded-lg">
                <div className="flex flex-wrap gap-2">
                  {<CartGridPOSWithSub />}
                </div>
              </div>
            )}
          </>
        )}
      </ScrollableFrame>
    </div>
  );
};

export default POSSale;
