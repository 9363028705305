import dayjs from "dayjs";

export const convertObjectToArray = (data: any) => {
  return Object.entries(data);
};

export const getActivePrice = (prices: any) => {
  return prices?.find((price: any) => price.isActive)?.value;
};

export const getTotalPrice = (list: any) => {
  return list
    ?.map(
      (item: any, _index: number) =>
        item?.payment?.amount +
        (item?.payment?.amount * item?.payment?.extendedWarranty) / 100
    )
    ?.reduce((total: any, price: any) => (total += parseInt(price)), 0);
};

export const getTotalPaidAmount = (list: any) => {
  if (list?.length > 0)
    return list
      ?.map((item: any, _index: number) => item.amount)
      ?.reduce((total: number, amount: number) => (total += amount));
};

export const getActiveShop = (shops: any) => {
  if (!shops) {
    return [];
  }
  return shops?.filter((el: any) => el?.isActive === true);
};

/**
 * Calculates the number of days between two dates.
 *
 * @param dates - An array of two ISO-formatted date strings (e.g. "2023-05-02").
 * @returns The number of days between the two dates.
 */
export const getDaysBetweenDates = (dates: [string, string]): number => {
  const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
  const startDate = new Date(dates[0]);
  const endDate = new Date(dates[1]);
  const diffDays = Math.round(
    Math.abs((startDate.getTime() - endDate.getTime()) / oneDay)
  );
  return diffDays;
};

export const getMatchingPayments = (paymentMethods: any, ids: any) => {
  const filteredPayments = paymentMethods?.filter((payment: any) =>
    ids?.includes(payment?._id)
  );
  return filteredPayments;
};
export const getPercentage = (paymentMethods: any, id: any, total: any) => {
  const amount = paymentMethods?.find((payment: any) => payment._id === id);
  if (amount?.totalPayment > 0) {
    return ((amount?.totalPayment * 100) / total)?.toFixed(1);
  }
};

export const convertArrayToObject = (data: any) =>
  data?.reduce(
    (result: any, item: any) => ({
      ...result,
      [item?.shopName || item?.name || item?.agentName || item?.hour]: parseInt(
        item?.totalPayment ||
          item?.soldItems ||
          item?.totalQuantity ||
          item?.revenue
      ),
    }),
    {}
  );

export const convertArrayToObjects = (data: any) =>
  data?.reduce(
    (result: any, item: any) => ({
      ...result,
      [item?.brand || item?.model || item?.type]: parseInt(item?.quantity),
    }),
    {}
  );

export const sortObjectAlphabetically = (obj: any) => {
  return Object.fromEntries(
    Object?.entries(obj)?.sort(([keyA], [keyB]) => keyA?.localeCompare(keyB))
  );
};

export const calculatePercentageChange = (v1: any, v2: any) => {
  if (v1 === 0 && v2 === 0) {
    return {
      type: "none",
      percentage: "0",
    };
  }
  // const percentageChange =
  //   Math.abs(
  //     (v1 - v2) / (v1 + v2)
  //   ) * 100;

  if (v1 > v2) {
    const percentageChange = Math.abs((v1 - v2) / v1) * 100;
    return {
      type: "increase",
      percentage: percentageChange.toFixed(1),
    };
  } else if (v1 < v2) {
    const percentageChange = Math.abs((v1 - v2) / v2) * 100;
    return {
      type: "decrease",
      percentage: Math.abs(percentageChange).toFixed(1),
    };
  } else {
    return {
      type: "none",
      percentage: 0,
    };
  }
};

interface Entry {
  mode: string;
  revenue: any;
}

export const separatePaymentsByMode = (
  data: Entry[],
  mode: string
): Entry[] => {
  const separatedData: Entry[] = [];
  data?.forEach((entry) => {
    if (entry?.mode === mode) {
      separatedData?.push(entry);
    }
  });
  return separatedData;
};

export const calculatePaymentTotalRevenue = (data: any, dateKey: string) => {
  let totalRevenue = 0;
  data?.forEach((entry: any) => {
    if (
      entry?.revenue &&
      entry?.revenue[dateKey] &&
      entry?.revenue[dateKey]?.revenueTotal
    ) {
      totalRevenue += entry?.revenue[dateKey]?.revenueTotal;
    }
  });
  return totalRevenue;
};

export const toIsoDate = (date: string) => {
  const currentTime = dayjs().utcOffset(4).format("HH:mm:ss");
  return dayjs(`${date} ${currentTime}`, "DD/MM/YYYY HH:mm:ss").toISOString();
};

export const getActivePlan: (
  plans: any,
  subscriptionPlan: number
) => Array<any> = (plans: any, subscriptionPlan: number) => {
  if (plans) {
    return plans[0]?.filter((plan: any) => plan?.duration === subscriptionPlan);
  }
};

export const getMomoPayments = (data: any) => {
  const payments = data?.createdCart?.data?.payment;
  const momoPayments = payments?.filter(
    (payment: any) => payment?.mode === "MoMo_Pay" // Todo: Update with momo
  );
  return momoPayments;
};

export const getLastPaymentStatus = (autoPayments: any) => {
  if (autoPayments?.length > 0) {
    const lastPayment = autoPayments[autoPayments?.length - 1];
    return lastPayment?.status;
  }
};

type Option = {
  product?: {
    model?: string;
  };
  label?: string;
};

type SetFilteredOptions = React.Dispatch<React.SetStateAction<Option[] | any>>;

export const searchProduct = (
  value: string,
  data: any,
  filterFunction: (option: Option, value: string) => boolean,
  setFilteredOptions: SetFilteredOptions
) => {
  const filtered = data?.filter((option: any) => filterFunction(option, value));
  setFilteredOptions(filtered);
};

export const handleClearAllState = (dispatch: any, actions: any) => {
  actions?.forEach((action: any) => {
    dispatch(action(null));
  });
};
