import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Modal,
  Select,
  DatePicker,
  Divider,
  DatePickerProps,
} from "antd";
import LoadingButton from "@mui/lab/LoadingButton";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import {
  createCustomerAction,
  updateCustomerAction,
} from "../../../store/customer/actions";
import { handleNextStep } from "../../../store/layout/actions";
import "./address.css";
import { LinearProgress, useMediaQuery } from "@mui/material";
import AddressDetailsForm from "./AddressDetailsForm";
// import WitnessForm from "./WitnessForm";
import { Button } from "@mui/material";
import dayjs from "dayjs";
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const PersonnelDetailsForm = (props: any) => {
  const { wareHouse, auth, customer } = useSelector((state: any) => state);
  const { new: newCustomer } = useSelector((state: any) => state.customer);
  const [selectedCountry, setSelectedCountry] = useState("250");
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<any>([
    "0787486966",
  ]);
  const formattedDate = new Date().toISOString().slice(0, 10);
  const [selectedDateOfBirth, setSelectedDateOfBirth] = useState<any>(
    wareHouse?.createdCart?.data?.customer?.dateOfBirth ?? formattedDate
  );
  // const [capturedImage, setCapturedImage] = useState<any | null>(null);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [openOtp, setOpenOtp] = useState(false);
  const handleOpenOtp = () => setOpenOtp(true);
  const handleCloseOtp = () => setOpenOtp(false);
  // const [otp, setOtp] = React.useState("");
  const [otherTypeOfIncome, setOtherTypeOfIncome] = useState(false);

  const dateFormat = "YYYY/MM/DD";
  const NotTablet = useMediaQuery("(min-width: 1536px)");

  const address =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address;
  const witnessInfo =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.witnessInfo;
  const nationalId =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.nationalIDDoc;
  const momoStatement =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      ?.MomoStatementDoc &&
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      ?.MomoStatementDoc[0];
  const nationalIDBack =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.photoBackOfID;
  const nationalIdHodingId =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      ?.photoCustomerHoldingID;

  useEffect(() => {
    if (wareHouse?.createdCart?.data?.customer) {
      form.setFieldsValue(wareHouse?.createdCart?.data?.customer);
    } else {
      form.resetFields();
    }
  }, [form, wareHouse?.createdCart?.data?.customer]);

  const onFinish = async (values: any) => {
    const name = `${values?.firstName}  ${values?.lastName}`;

    if (wareHouse?.createdCart?.data?.customer?._id && address && auth?.token) {
      await updateCustomerAction(
        auth?.token,
        wareHouse?.createdCart?.data?.customer?._id,
        {
          ...values,
          name,
          otherPhone: [{ tel: values?.secondaryPhone }],
          dateOfBirth: selectedDateOfBirth,
          subscriptionInfo: {
            address: values,
            witnessInfo: witnessInfo,
            nationalIDDoc: nationalId,
            photoBackOfID: nationalIDBack,
            photoCustomerHoldingID: nationalIdHodingId,
            MomoStatementDoc: [momoStatement],
            mainIncomeSource: values?.mainIncomeSource,
            incomeFrequency: values?.incomeFrequency,
            monthlyIncome: values?.monthlyIncome,
            occupationOrBusinessType: values?.occupationOrBusinessType,
            ownsSmartphone: values?.ownsSmartphone,
            secondaryPhone: values?.secondaryPhone,
          },
        }
      )(dispatch);
      await handleNextStep(0)(dispatch);
    } else {
      const response = await createCustomerAction(auth?.token, {
        ...values,
        name,
        otherPhone: [{ tel: values?.secondaryPhone }],
        dateOfBirth: selectedDateOfBirth,
        subscriptionInfo: {
          address: values,
          mainIncomeSource: values?.mainIncomeSource,
          monthlyIncome: values?.monthlyIncome,
          occupationOrBusinessType: values?.occupationOrBusinessType,
          incomeFrequency: values?.incomeFrequency,
          ownsSmartphone: values?.ownsSmartphone,
          secondaryPhone: values?.secondaryPhone,
        },
      })(dispatch);
      if (response) {
        await handleNextStep(0)(dispatch);
        form.resetFields();
      }
    }
  };

  useEffect(() => {
    if (wareHouse?.createdCart?.data?.customer) {
      form.setFieldsValue(wareHouse?.createdCart?.data?.customer);
    } else if (
      wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address
    ) {
      form.setFieldsValue(
        wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address
      );
    } else {
      form.resetFields();
    }
  }, [
    form,
    wareHouse?.createdCart?.data?.customer,
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address,
  ]);

  const initialData = {
    ...wareHouse?.createdCart?.data?.customer,
    ...wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address,
    ...wareHouse?.createdCart?.data?.customer?.subscriptionInfo,
    secondaryPhone:
      wareHouse?.createdCart?.data?.customer?.otherPhone?.at(0)?.tel,
    firstName: wareHouse?.createdCart?.data?.customer?.name?.split(" ")[0],
    lastName: wareHouse?.createdCart?.data?.customer?.name?.slice(
      wareHouse?.createdCart?.data?.customer?.name?.split(" ")[0]?.length
    ),
  };

  console.log(">>>>>:::::", wareHouse?.createdCart?.data?.customer);

  const handleCountryChange = (country: any) => {
    setSelectedCountry(country);
  };

  const handleConfirmOtp = async () => {
    await handleNextStep(0)(dispatch);
  };

  const handleRadioChange = (event: any) => {
    setSelectedPhoneNumber(event.target.value);
  };

  const onChangeDate: DatePickerProps["onChange"] = async (
    date: any,
    dateString: any
  ) => {
    setSelectedDateOfBirth(dateString);
  };

  return (
    <>
      <div className="h-[100%] bg-white p-2 rounded-lg overflow-y-auto ">
        <div className="pb-5">
          <div className="flex flex-row justify-between pb-2">
            <div>Personal Details</div>
            <div>
              <span className="text-[#5F5AFE]">Step 1</span> of 5
            </div>
          </div>
          <LinearProgress variant="determinate" value={10} />
        </div>
        <Form
          {...formItemLayout}
          form={form}
          name="register"
          layout="vertical"
          onFinish={onFinish}
          initialValues={initialData || {}}
          scrollToFirstError
          className="antd-custom-style xmin-h-screen  space-y-4 capitalize"
        >
          <div className=" rounded-lg">
            <div className="grid grid-cols-2 px-4 pt-10">
              <Form.Item
                name="firstName"
                className="w-[90%]"
                label={
                  <span className="text-[#0F0F47] text-sm">First Name</span>
                }
                rules={[
                  {
                    required: true,
                    message: "First Name is required!",
                    whitespace: true,
                  },
                ]}
                getValueFromEvent={(e) =>
                  e.target.value.charAt(0).toUpperCase() +
                  e.target.value.slice(1)
                }
              >
                <Input
                  className="pl-4  h-[52px] w-[100%] lg:max-2xl:w-[200px] lg:max-2xl:h-[35px] bg-[#EFF0F6] border-none"
                  placeholder="First Name"
                />
              </Form.Item>
              <Form.Item
                name="lastName"
                className="w-[100%]"
                label={
                  <span className="text-[#0F0F47] text-sm">Last Name</span>
                }
                // initialValue={props?.dataToUpdate?.name
                //   ?.slice(firstNameToUpdate?.length)
                //   ?.trim()}
                rules={[
                  {
                    required: true,
                    message: "Last Name is required!",
                    whitespace: true,
                  },
                ]}
                getValueFromEvent={(e) =>
                  e.target.value.charAt(0).toUpperCase() +
                  e.target.value.slice(1)
                }
              >
                <Input
                  className="pl-4 h-[52px] w-[100%] lg:max-2xl:w-[200px] lg:max-2xl:h-[35px] bg-[#EFF0F6] border-none"
                  placeholder="Last Name"
                />
              </Form.Item>
              <Form.Item
                name="nationalId"
                className="w-[90%]"
                label={
                  <span className="text-[#0F0F47] text-sm">National ID</span>
                }
                rules={[
                  {
                    required: true,
                    message: "National ID is required!",
                    whitespace: true,
                  },
                ]}
                getValueFromEvent={(e) =>
                  e.target.value.charAt(0).toUpperCase() +
                  e.target.value.slice(1)
                }
              >
                <Input
                  className="pl-4  h-[52px] w-[100%] lg:max-2xl:w-[200px] lg:max-2xl:h-[35px] bg-[#EFF0F6] border-none"
                  placeholder="National Id"
                />
              </Form.Item>
              <Form.Item
                name="email"
                className="w-[100%]"
                label={<span className="text-[#0F0F47] text-sm">Email</span>}
                initialValue={props?.dataToUpdate?.email}
              >
                <Input
                  className="pl-4 h-[52px] w-[100%] lg:max-2xl:w-[200px] lg:max-2xl:h-[35px] bg-[#EFF0F6] border-none"
                  placeholder="Email"
                />
              </Form.Item>
              <Form.Item
                name="phone"
                className="w-[100%] lg:max-2xl:w-72 lg:max-2xl:-mt-2"
                label={
                  <span className="text-[#0F0F47] text-sm">
                    <span className="text-red-500">*</span> Primary Phone Number
                  </span>
                }
                initialValue={props?.dataToUpdate?.phone}
                rules={[
                  {
                    validator: (_, value) => {
                      const phoneNumberLength = value
                        ? value.replace(/\D/g, "").length
                        : 0;
                      if (selectedCountry?.slice(0, 3) === "250") {
                        if (phoneNumberLength === 12) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Phone Number should be exactly 9 Digits.")
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <PhoneInput
                  onChange={handleCountryChange}
                  country={"rw"}
                  buttonStyle={{
                    height: NotTablet ? "52px" : "35px",
                    border: "none",
                    borderRight: "3px solid #fff",
                    backgroundColor: "#EFF0F6",
                  }}
                  inputStyle={{
                    height: NotTablet ? "52px" : "35px",
                    width: NotTablet ? "90%" : "100%",
                    backgroundColor: "#EFF0F6",
                    border: "none",
                  }}
                  containerClass="phone-container"
                  placeholder="+250 780 000 000"
                />
              </Form.Item>
              <Form.Item
                name="secondaryPhone"
                className="w-[100%] lg:max-2xl:w-72 lg:max-2xl:-mt-2"
                label={
                  <span className="text-[#0F0F47] text-sm">
                    Secondary Phone Number
                  </span>
                }
                initialValue={props?.dataToUpdate?.phone}
              >
                <PhoneInput
                  onChange={handleCountryChange}
                  country={"rw"}
                  buttonStyle={{
                    height: NotTablet ? "52px" : "35px",
                    border: "none",
                    borderRight: "3px solid #fff",
                    backgroundColor: "#EFF0F6",
                  }}
                  inputStyle={{
                    height: NotTablet ? "52px" : "35px",
                    width: NotTablet ? "100%" : "100%",
                    backgroundColor: "#EFF0F6",
                    border: "none",
                  }}
                  containerClass="phone-container"
                  placeholder="+250 780 000 000"
                />
              </Form.Item>
              <Form.Item
                className="w-[90%]"
                style={{ border: "none" }}
                name="gender"
                label={
                  <span className="text-[#0F0F47] text-[12px]">Gender</span>
                }
                rules={[
                  {
                    required: true,
                    message: "Gender is required!",
                    whitespace: true,
                  },
                ]}
              >
                <Select className="h-[52px] border-none">
                  {["male", "female"].map((el: any) => (
                    <Option key={el} className="capitalize">
                      {el}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="dateBirth"
                className="w-[100%]"
                label={
                  <span className="text-[#0F0F47] text-sm">Date of Birth</span>
                }
              >
                <DatePicker
                  className="w-[100%] h-[52px]"
                  onChange={onChangeDate}
                  defaultValue={dayjs(selectedDateOfBirth, dateFormat)}
                />
              </Form.Item>
              <Form.Item
                className="w-[90%]"
                style={{ border: "none" }}
                name="maritalStatus"
                label={
                  <span className="text-[#0F0F47] text-[12px]">
                    Martial Status
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Marital Status is required!",
                    whitespace: true,
                  },
                ]}
              >
                <Select className="h-[52px] border-none">
                  {[
                    "single",
                    "married",
                    "divorced",
                    "widowed",
                    "separated",
                  ].map((el: any) => (
                    <Option key={el} className="capitalize">
                      {el}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>
          {/* <div>
            <div>
              <Form.List name="otherPhone">
                {(fields, { add, remove }) => (
                  <>
                    <Form.Item>
                      <Button
                        onClick={() => add()}
                        style={{
                          textTransform: "capitalize",
                          fontSize: "15px",
                        }}
                      >
                        <PlusOutlined /> Other Phone Number
                      </Button>
                    </Form.Item>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          width: "30%",
                        }}
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "tel"]}
                          rules={[
                            {
                              validator: (_, value) => {
                                const phoneNumberLength = value
                                  ? value.replace(/\D/g, "").length
                                  : 0;
                                if (selectedCountry?.slice(0, 3) === "250") {
                                  if (phoneNumberLength === 12) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error(
                                      "Phone Number should be exactly 9 Digits."
                                    )
                                  );
                                } else {
                                  return Promise.resolve();
                                }
                              },
                            },
                          ]}
                          style={{
                            width: "100%",
                            border: "none",
                            height: "52px",
                            backgroundColor: "#EFF0F6",
                          }}
                        >
                          <PhoneInput
                            onChange={handleCountryChange}
                            country={"rw"}
                            buttonStyle={{
                              height: NotTablet ? "50px" : "35px",
                              border: "none",
                              borderRight: "3px solid #fff",
                              backgroundColor: "#EFF0F6",
                            }}
                            containerClass="phone-container"
                            placeholder="+250 780 000 000"
                            inputStyle={{
                              width: "350px",
                              border: "none",
                              height: "52px",
                              backgroundColor: "#EFF0F6",
                            }}
                          />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                  </>
                )}
              </Form.List>
            </div>
          </div> */}
          <Divider />
          <div>
            <h1 className="text-black text-lg pl-4 pb-4 font-semibold">
              Financial Details
            </h1>
          </div>
          <div className="grid grid-cols-2 px-4">
            <Form.Item
              name="mainIncomeSource"
              className="w-[90%]"
              label={
                <span className="text-[#0F0F47] text-sm">
                  Main Source of Income
                </span>
              }
              rules={[
                {
                  required: true,
                  message: "Source  of Income is required!",
                  whitespace: true,
                },
              ]}
            >
              <Select
                className="h-[52px] border-none"
                onChange={(values) =>
                  values === "Other" && setOtherTypeOfIncome(true)
                }
              >
                {["Employed", "Own Business"].map((el: any) => (
                  <Option key={el} className="capitalize">
                    {el}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="occupationOrBusinessType"
              className="w-[100%]"
              label={<span className="text-[#0F0F47] text-sm">Occupation</span>}
              rules={[
                {
                  required: true,
                  message: "Occupation is required!",
                  whitespace: true,
                },
              ]}
            >
              <Select
                className="h-[52px] border-none"
                onChange={(values) =>
                  values === "Other" && setOtherTypeOfIncome(true)
                }
              >
                {[
                  "Agriculture and Farming",
                  "Retail and Commerce",
                  "Service",
                  "Manufacturing and Production",
                  "Transport and Logistics",
                  "Technology and IT",
                  "Construction and Real Estate",
                  "Health and Wellness",
                  "Education and Training",
                  "Art and Culture",
                  "Financial Services",
                  "Others",
                ].map((el: any) => (
                  <Option key={el} className="capitalize">
                    {el}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className="w-[90%]"
              style={{ border: "none" }}
              name="monthlyIncome"
              label={
                <span className="text-[#0F0F47] text-[12px]">
                  Monthly Income
                </span>
              }
              rules={[
                {
                  required: true,
                  message: "Income is required!",
                  whitespace: true,
                },
              ]}
            >
              <Select
                className="h-[52px] border-none"
                onChange={(values) =>
                  values === "Other" && setOtherTypeOfIncome(true)
                }
              >
                {[
                  "<50k",
                  "50k-100k",
                  "100k-150k",
                  "150k-200k",
                  "200k-250k",
                  "250k-300k",
                  "300k-350k",
                  "350k-400k",
                  "400k-600k",
                  "> 600k",
                ].map((el: any) => (
                  <Option key={el} className="capitalize">
                    {el}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="incomeFrequency"
              className="w-[100%]"
              label={
                <span className="text-[#0F0F47] text-sm">Income Frequency</span>
              }
              initialValue={props?.dataToUpdate?.email}
              rules={[
                {
                  required: true,
                  message: "Income Frequency is required!",
                  whitespace: true,
                },
              ]}
            >
              <Select
                className="h-[52px] border-none"
                onChange={(values) =>
                  values === "Other" && setOtherTypeOfIncome(true)
                }
              >
                {["Daily", "Weekly", "Monthly", "Seasonally"].map((el: any) => (
                  <Option key={el} className="capitalize">
                    {el}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className="w-[90%]"
              style={{ border: "none" }}
              name="ownsSmartphone"
              label={
                <span className="text-[#0F0F47] text-[12px]">
                  Do you currently own a smartphone?
                </span>
              }
              rules={[
                {
                  required: true,
                  message: "Field is required!",
                  whitespace: true,
                },
              ]}
            >
              <Select className="h-[52px] border-none">
                {["Yes", "No"].map((el: any) => (
                  <Option key={el} className="capitalize">
                    {el}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <Divider />
          <div>
            <AddressDetailsForm removeCustomer={props?.onCancel} />
          </div>
          {/* <div className="pb-20">
            <WitnessForm removeCustomer={props?.onCancel} />
          </div> */}
          <div className="pb-20 flex justify-end">
            <LoadingButton
              type="submit"
              variant="contained"
              style={{
                padding: "7px",
                width: "20%",
              }}
              loading={customer?.isFetching}
            >
              {"Save and Continue"}
            </LoadingButton>
          </div>
        </Form>
      </div>
      <Modal
        footer={null}
        open={openOtp}
        onCancel={handleCloseOtp}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="flex justify-center items-center h-full rounded-md capitalize">
          <div className="bg-white px-4 py-4 space-y-2 w-[60%] h-auto text-black">
            <div className="flex justify-center font-poppins text-base font-medium">
              Confirm with OTP
            </div>
            <div className="flex justify-center font-poppins text-base font-medium">
              Select phone number
            </div>
            <div className="flex justify-center border border-gray-300 rounded-md p-3">
              <input
                type="radio"
                name="phoneNumber"
                className="pl-4 cursor-pointer"
                value={wareHouse?.createdCart?.data?.customer?.phone}
                checked={
                  selectedPhoneNumber ===
                  wareHouse?.createdCart?.data?.customer?.phone
                }
                onChange={handleRadioChange}
              />
              {wareHouse?.createdCart &&
                parseInt(wareHouse?.createdCart?.data?.customer?.phone)}
            </div>
            {wareHouse?.createdCart?.data?.customer?.otherPhone.map(
              (item: any) => (
                <div className="flex justify-center border border-gray-300 rounded-md p-3">
                  <input
                    type="radio"
                    name="otherPhoneNumber"
                    className="pl-4 cursor-pointer"
                    value={item.tel}
                    checked={selectedPhoneNumber === item.tel}
                    onChange={handleRadioChange}
                  />
                  {item.tel}
                </div>
              )
            )}
            <div>
              {/* <div className="flex justify-center pb-3">
                <div className="flex flex-col align-start py-4 w-[100%] h-[80px]">
                  <div className="flex flex-row  w-[100%] items-center justify-between gap-2 pb-2">
                    <div className="text-gray-300 flex flex-row">
                      <MuiOtpInput value={otp} onChange={handleChange} />
                    </div>
                    <div className="text-[#5F5AFE] cursor-pointer items-center  flex flex-row">
                      <div><ReloadOutlined /></div>
                      <div>Sendagain</div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="flex justify-center pt-[50px] gap-10">
              <Button
                style={{ backgroundColor: "#5F5AFE", color: "white" }}
                onClick={handleConfirmOtp}
                className="w-[120px] h-[50px] text-lg"
              >
                <p>Send Otp</p>
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PersonnelDetailsForm;
