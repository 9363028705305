import React from "react";
import { useSelector } from "react-redux";
import CartCardGridPOSWithSub from "./CartCardGridPOSWithSub";

const CartGridPOSWithSub = (props: any) => {
  const { product, pos } = useSelector((state: any) => state);

  return pos?.carts?.data?.map(
    (d: any) =>
      d?.isSubscription === "subscription" && (
        <CartCardGridPOSWithSub
          data={d}
          handleSelected={props?.handleSelected}
          types={product?.attributes?.types}
        ></CartCardGridPOSWithSub>
      )
  );
};

export default CartGridPOSWithSub;
