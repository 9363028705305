import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const createConsentService = async (data: any, token: string) => {
  return await HttpRequest.post(
    `${SERVER_URL}/partner/yellow/legalAgreement`,
    data,
    token
  );
};

export const paymentRequestService = async (
  data: any,
  token: string,
  itemId: string
) => {
  return await HttpRequest.post(
    `${SERVER_URL}/partner/yellow/application/triggerDeposit/${itemId}`,
    data,
    token
  );
};

export const getAllConsentService = async (token: string, query?: string) => {
  return HttpRequest.get(
    `${SERVER_URL}/partner/yellow/legalAgreement${query}`,
    token
  );
};

export const getOneConsentService = async (token: any, itemId: string) => {
  return HttpRequest.get(
    `${SERVER_URL}/partner/yellow/legalAgreement/one/${itemId}`,
    token
  );
};

export const updateConsentService = async (
  token: string,
  itemId: string,
  data: any
) => {
  return HttpRequest.update(
    `${SERVER_URL}/partner/yellow/legalAgreement/one/${itemId}`,
    data,
    token
  );
};

export const createApplicationService = async (data: any, token: string) => {
  return await HttpRequest.post(
    `${SERVER_URL}/partner/yellow/application`,
    data,
    token
  );
};

export const sendContractService = async (data: any, token: string) => {
  return await HttpRequest.post(
    `${SERVER_URL}/partner/yellow/application/contract`,
    data,
    token
  );
};

export const uploadDeviceToYellowTenantService = async (
  data: any,
  token: string,
  itemId: any
) => {
  return await HttpRequest.post(
    `${SERVER_URL}/vault/uploadYellowDevice/${itemId}`,
    data,
    token
  );
};

export const replaceDeviceToYellowService = async (
  data: any,
  token: string
) => {
  return await HttpRequest.post(
    `${SERVER_URL}/partner/yellow/application/changeDevice`,
    data,
    token
  );
};

export const uploadEBMToYellowService = async (
  data: any,
  token: string,
  saleId: string
) => {
  return await HttpRequest.post(
    `${SERVER_URL}/partner/yellow/application/uploadInvoice/${saleId}`,
    data,
    token
  );
};
