import { useEffect, useState } from "react";
import { getAllRepairsAction } from "../../../store/repair/actions";
import { useDispatch, useSelector } from "react-redux";
import RepairCard from "../../../components/cards/pos/RepairCard";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";

const RepairDevices = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);
  const { auth, repair } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    auth?.token &&
      getAllRepairsAction(
        auth?.token,
        `?limit=${limit}&page=${page - 1}`
      )(dispatch);
    // getAllRepairsAction(auth?.token, `?shop=${auth?.user?._id}`)(dispatch);
  }, [auth?.token, auth.user._id, dispatch, limit, page]);
  return (
    <div>
      <ScrollableFrame
        loading={repair?.isFetching}
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={repair?.all?.total}
        count={Math.ceil(repair?.all?.total / limit)}
      >
        <div className="grid md:grid-cols-2 xl:grid-cols-4 gap-2">
          {repair?.all?.data?.map((request: any) => (
            <RepairCard repairRequest={request} />
          ))}
        </div>
      </ScrollableFrame>
    </div>
  );
};

export default RepairDevices;
