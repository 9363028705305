import React, { ReactInstance, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { customerSignatureAction } from "../../../store/pos/actions";
import { useDispatch, useSelector } from "react-redux";
import "./address.css";
import { useParams } from "react-router-dom";
import { updateShopStockCartDetailsAction } from "../../../store/wareHouse/actions";
import { LoadingButton } from "@mui/lab";
import { Button, notification } from "antd";
import { useReactToPrint } from "react-to-print";
import { handleNextStep } from "../../../store/layout/actions";
import { FaBackward } from "react-icons/fa6";

const Signature = (props: any, handleClose: any) => {
  const { auth, pos } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const { cartId } = useParams();
  const [sign, setSign] = useState<SignatureCanvas | null>(null);
  const templateRef = useRef<ReactInstance>(null);

  const handlePrint = useReactToPrint({
    content: () => templateRef.current,
    // pageStyle: `
    // @media print {
    //   @page {
    //     size: 58mm auto;
    //     margin: 0;
    //   }
    // }
    // `,
  });

  const handleClear = () => {
    sign?.clear();
    // props?.setCustomerSignature("");
  };

  const handleSignature = async () => {
    const trimmedCanvas = sign?.getTrimmedCanvas();
    if (trimmedCanvas) {
      const dataUrl = trimmedCanvas.toDataURL("image/png");
      if (dataUrl) {
        await customerSignatureAction(auth?.token, {
          image: dataUrl,
        })(dispatch);
        // await updateShopStockCartDetailsAction(auth?.token, cartId, {
        //   customerSignature: pos?.customerSignature?.data?.secure_url,
        //   acceptTermsAndConditions: true,
        // })(dispatch);
        await handleNextStep(1)(dispatch);
        props?.handleClose();
      }
    }
  };
  const openNotification = () => {
    notification.error({
      message: "Customer signature is required",
    });
  };

  const checkSignature = () => {
    if (sign?.isEmpty()) {
      openNotification();
    } else {
      handleSignature();
      props?.handleClose();
    }
  };
  return (
    <div className="min-h-52 w-[100%] relative">
      <p className="text-[#5F5AFE] text-lg text-center font-bold">
        Sign Consent
      </p>
      <div className=" absolute left-0 top-0">
        <Button
          className="bg-[#5F5AFE] text-white"
          onClick={() => {
            props?.handleClose();
          }}
        >
          <FaBackward />
        </Button>
      </div>
      <SignatureCanvas
        canvasProps={{
          height: 350,
          className:
            "mt-4 h-[350px] w-[100%] border-dotted border-2 border-indigo-300  py-2 cursor-pointer",
        }}
        ref={(data: any) => setSign(data)}
        penColor="black"
      />
      <div className="flex justify-center mt-4 space-x-4">
        <LoadingButton
          variant="outlined"
          style={{
            width: "100px",
          }}
          className="bg-[#5F5AFE] text-white"
          onClick={handleClear}
        >
          Clear
        </LoadingButton>
        <LoadingButton
          //  loading={posYelllow?.isFetching}
          variant="contained"
          style={{
            width: "120px",
          }}
          className="bg-[#5F5AFE] text-white"
          onClick={() => {
            checkSignature();
            handlePrint();
          }}
        >
          Confirm
        </LoadingButton>
      </div>
    </div>
  );
};

export default Signature;
