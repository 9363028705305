import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { Badge, Tag, Button, Modal, notification } from "antd";
import ProductDetailsModal from "../../../Modals/ProductDetailsModal";
import UpdateProduct from "../../../forms/NewProductForm";
import DeleteModal from "../../../Modals/DeleteModal";
import {
  getActivePlan,
  getActivePrice,
  getActiveShop,
  getTotalPrice,
} from "../../../../utils/converter";
import { useNavigate } from "react-router-dom";
import { searchValue } from "../../../../utils/setColor";
import {
  deleteCartAction,
  getAllCartsAction,
} from "../../../../store/pos/actions";
import {
  getAllShopStockAction,
  getShopStockCartAction,
  replaceDeviceToCartAction,
  updateShopStockCartDetailsAction,
} from "../../../../store/wareHouse/actions";
import { myWareHouseActions } from "../../../../store/wareHouse";
import {
  replaceDeviceToYellowAction,
  uploadDeviceToYellowTenantAction,
  uploadEBMToYellowAction,
} from "../../../../store/posYellow/actions";
import LockingSteps from "../../../forms/lockingSteps/LockingSteps";
import YellowModel from "../../../Modals/YellowModel";
import ReplaceDevice from "../../../cards/yellowCards/ReplaceDevice";
import UploadEBMData from "../../../cards/yellowCards/UploadEBMData";
import helpDeskImg from "../../../../assets/images/frontdesk.jpg";
import DeviceVerification from "../../../cards/yellowCards/DeviceVerification";

const CartCardGridPOSWithSub = (props: any) => {
  const { auth, pos, appSettings, wareHouse } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isShowNextStep, setIsShowNextStep] = useState(false);
  const [makePayment, setMakePayment] = useState(false);
  const [verifyDevice, setVerifyDevice] = useState(false);
  const [scannedCode, setScannedCode] = useState<string>();
  const [newSearch, setNewSearch] = useState<boolean>(false);
  const [uploadIBM, setUploadIBM] = useState<boolean>(false);
  const [selectedCart, setSelectedCart] = useState<any>("");
  const [invoiceNumber, setInvoiceNumber] = useState(null);
  const [replacement, setReplacement] = useState<boolean>(false);
  const [deviceHasIssie, setDeviceHasIssie] = useState<boolean>(false);
  const [pictures, setPictures] = useState<any>([]);

  const getStatus = (yellowStatus: any) => {
    const statusMap: Record<any, any> = {
      pending_credit_decision: "processing eligibility",
      pending_signature: "eligible",
      pending_yellow_setup: "processing eligibility",
      pending_asset_transfer: "Device verification",
      pending_device_locking: "locking process",
      pending_deposit: "waiting for fund deposit",
      pending_ebm_upload: "upload ebm receipt",
      pending_resubmission: "change requested",
      success: "done",
    };

    return statusMap[yellowStatus.toLowerCase()] || yellowStatus;
  };

  const productData = wareHouse?.searchResult;

  const showNextModal = () => {
    setIsShowNextStep(true);
  };

  const handleUploadIBMModel = () => {
    setUploadIBM(true);
  };

  const handleReplacementModal = () => {
    setReplacement(true);
    setVerifyDevice(false);
  };

  const handleVerifyDeviceModal = () => {
    setVerifyDevice(true);
    setReplacement(false);
  };

  const handleDeviceHasIssuesModal = () => {
    setDeviceHasIssie(true);
  };

  const handleDeviceLockiModal = () => {
    setMakePayment(true);
  };
  const handleClickDelete = async () => {
    setVisible(true);
  };
  const handleOnClickViewIcon = (e: any) => {
    e.preventDefault();
    setIsModalOpen(true);
  };
  const handleDeleteModalCancels = () => {
    setVisible(false);
  };
  const handleOnCancel = () => {
    setIsModalOpen(false);
    dispatch(myWareHouseActions.setSearchResult(null));
  };

  const handleCancelNextModel = async () => {
    setIsShowNextStep(false);
    setMakePayment(false);
    setReplacement(false);
    setPictures([]);
    setDeviceHasIssie(false);
    dispatch(myWareHouseActions.setIsReplaced(false));
    dispatch(myWareHouseActions.setIsCartFound(false));
    dispatch(myWareHouseActions.setSearchResult(null));
    const activeShop = getActiveShop(auth?.user?.shop?.assigned);
    if (auth?.token) {
      getAllCartsAction(
        auth?.token,
        `?shop=${
          activeShop[appSettings?.selectedShopNumber]?.shop?._id ??
          auth?.user?.shop?.shopId
        }&status[]=pending&status[]=rejected&status[]=accepted&status[]=requested&status[]=inquiry&status[]=review`
      )(dispatch);
    }
  };

  const deleteProduct = async () => {
    const activeShop = getActiveShop(auth?.user?.shop?.assigned);
    const shopId = activeShop
      ? activeShop[appSettings?.selectedShopNumber]?.shop?._id
      : auth?.user?.shop?.shopId;
    auth?.token &&
      (await deleteCartAction(
        auth?.token,
        props?.data?._id,
        `?shop=${shopId}&status=paid`
      )(dispatch));
    auth?.token &&
      (await getAllCartsAction(
        auth?.token,
        `?shop=${
          activeShop[appSettings?.selectedShopNumber]?.shop?._id ??
          auth?.user?.shop?.shopId
        }&status[]=pending&status[]=accepted&status[]=rejected&status[]=requested&status[]=inquiry&status[]=review`
      )(dispatch));
    setVisible(false);
  };

  useEffect(() => {
    dispatch(myWareHouseActions.setCreatedCart(null));
    dispatch(myWareHouseActions.setIsCartFound(false));
  }, [dispatch]);

  const handleShowNextStep = (data: any) => {
    setSelectedCart(data);
    const { data: propsData } = props;

    switch (propsData?.yellowStatus?.toLowerCase()) {
      case "pending_credit_decision":
      case "pending_yellow_setup":
        showNextModal();
        break;
      case "pending_deposit":
      case "pending_device_locking":
        if (propsData?.hasIssue) {
          handleDeviceHasIssuesModal();
        } else if (propsData?.issues?.isReplacementNeed) {
          handleReplacementModal();
        } else {
          handleDeviceLockiModal();
        }
        break;
      case "pending_asset_transfer":
        handleVerifyDeviceModal();
        break;
      case "pending_ebm_upload":
        handleUploadIBMModel();
        break;
      case "rejected":
        break;
      default:
        navigate(propsData?._id);
        break;
    }
  };

  const status = getStatus(props?.data?.yellowStatus);

  const onFinish = async (values: any) => {
    auth?.token &&
      (await getAllShopStockAction(
        auth?.token,
        `?serialNumber=${values?.query}&shopId=${
          getActiveShop(auth?.user?.shop?.assigned)[
            appSettings?.selectedShopNumber
          ]?.shop?._id ?? auth?.user?.shop?.shopId
        }`
      )(dispatch));
  };

  const handleChange = (e: any) => {
    setNewSearch(true);
  };

  const getCode = (code: string) => {
    setNewSearch(false);
    onFinish({ query: code });
    setScannedCode(code);
  };

  const handleUploadDeviceToYellowTenant = async () => {
    const res = await uploadDeviceToYellowTenantAction(
      auth?.token,
      {},
      selectedCart?._id
    )(dispatch);
    if (res) {
      handleCancelNextModel();
    }
  };

  useEffect(() => {
    if (selectedCart?._id)
      getShopStockCartAction(auth?.token, selectedCart?._id)(dispatch);
  }, [auth?.token, dispatch, selectedCart?._id]);

  const handleSendIBMReceipt = async () => {
    if (
      pictures?.length === 0 ||
      pictures?.length === undefined ||
      [null, ""].includes(invoiceNumber)
    ) {
      return notification.error({
        message: "Please Upload IBM Receipt / Invoice Number",
      });
    } else {
      const res = await uploadEBMToYellowAction(
        auth?.token,
        wareHouse?.createdCart?.data?.saleId,
        {
          invoiceNumber: invoiceNumber,
          invoiceImage: pictures?.at(0)?.response?.data?.secure_url,
        }
      )(dispatch);
      if (res) {
        handleCancelNextModel();
      }
    }
  };

  const handleReplaceDevice = async () => {
    const subsPlans = wareHouse?.createdCart?.data?.list?.map(
      (plan: any, index: number) => plan?.priceProduct?.subscriptionPlan
    );

    const ActivePlan = getActivePlan(
      subsPlans,
      wareHouse?.createdCart?.data?.subscriptionPlan
    );
    const product = wareHouse?.searchResult?.data?.product;
    const payload = {
      cart: selectedCart?._id,
      shopStockItem: product?._id,
      warehouseProduct: product?.wareHouseItem?._id,
      channel: product?.shop?.channel?._id,
      shop: product?.shop?._id,
      priceProduct: product?.requestedItem?.product?._id,
      payment: {
        amount: +getActivePrice(product?.requestedItem?.product?.prices),
        extendedWarranty: 0,
        installementPayment: `${
          wareHouse?.createdCart?.data?.initialPaymentType === "b2c_prepaid"
            ? ActivePlan && ActivePlan.at(0)?.initialPayment
            : `${
                wareHouse?.createdCart?.data?.instalmentPlan === "daily"
                  ? ActivePlan && ActivePlan.at(0)?.dailyInstalment
                  : wareHouse?.createdCart?.data?.instalmentPlan === "weekly"
                  ? ActivePlan && ActivePlan.at(0)?.weeklyInstalment
                  : (ActivePlan && ActivePlan.at(0)?.monthlyInstalment) || 0
              }`
        }`,
      },
    };
    if (wareHouse?.isReplaced) {
      const res = await replaceDeviceToYellowAction(auth?.token, {
        imei: productData?.data?.product?.wareHouseItem?.serialNumber,
        saleId: wareHouse?.createdCart?.data?.saleId,
      })(dispatch);
      if (res) {
        await updateShopStockCartDetailsAction(auth?.token, selectedCart?._id, {
          hasIssue: false,
          issues: {
            isReplacementNeed: false,
          },
        })(dispatch);
        await handleCancelNextModel();
      }
    } else {
      await replaceDeviceToCartAction(
        auth?.token,
        selectedCart?._id,
        payload
      )(dispatch);
    }
  };

  return (
    <>
      <Badge.Ribbon
        color="green"
        text={`Total amount: ${getTotalPrice(
          props?.data?.list
        )?.toLocaleString()} RWF`}
      >
        <div
          className={`relative w-[23rem] h-fit p-2 bg-white text-black rounded-md ${
            props?.data?.hasIssue || props?.data?.issues?.isReplacementNeed
              ? "border border-red-500"
              : ""
          }`}
        >
          <div
            className="flex w-full pb-10 pt-10"
            onClick={() => handleShowNextStep(props?.data)}
          >
            <div className="flex flex-col flex-wrap pl-2">
              <h1 className="font-bold">{props?.data?.cartId}</h1>
              <div className=" gap-1">
                <p className="text-xs">
                  <b>Customer: </b>
                  {props?.data?.customer?.name}
                </p>
                <p className="text-xs">
                  <b>Created Date: </b>
                  {new Date(props?.data?.createdAt).toLocaleDateString(
                    "en-US",
                    {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    }
                  )}
                </p>
                <p className="text-xs">
                  <b>Created By: </b>
                  {props?.data?.createdBy?.names}
                </p>
              </div>
            </div>
          </div>
          {/* </Link> */}
          <div className="absolute bottom-1 right-2 flex justify-end items-center w-full py-1 space-x-2">
            <div className="capitalize">
              {props?.data?.issues?.isReplacementNeed && (
                <Tag
                  color={searchValue("Replace device")}
                  style={{ minWidth: "4rem", textAlign: "center" }}
                  className="py-1 text-sm"
                >
                  Replace device
                </Tag>
              )}

              <Tag
                color={searchValue(props?.data?.isSubscription)}
                style={{ minWidth: "4rem", textAlign: "center" }}
                className="py-1 text-sm"
              >
                {props?.data.isSubscription}
              </Tag>
              <Tag
                color={searchValue(status)}
                style={{ minWidth: "4rem", textAlign: "center" }}
                className="py-1 text-sm"
              >
                {status}
              </Tag>
            </div>
            {["admin", "dev"].includes(auth?.user?.role?.toLowerCase()) && (
              <button
                aria-label="delete"
                className="text-gray-400"
                onClick={handleClickDelete}
              >
                <DeleteIcon fontSize="small" className="h-2" />
              </button>
            )}
          </div>
        </div>
      </Badge.Ribbon>

      <ProductDetailsModal
        component={
          <UpdateProduct
            dataToUpdate={props?.data}
            action={"update"}
            onCancel={handleOnCancel}
          />
        }
        isModalOpen={isModalOpen}
        handleOnClickViewIcon={handleOnClickViewIcon}
        handleOnCancel={handleOnCancel}
      />

      <DeleteModal
        visible={visible}
        onOk={deleteProduct}
        isLoading={pos.isFetching}
        onCancel={handleDeleteModalCancels}
        itemName="Product"
      />

      {/* checking eligibility */}
      <YellowModel
        open={isShowNextStep}
        onCancel={handleCancelNextModel}
        content={
          <div>
            {["processing eligibility", "processing contract"]?.includes(
              status
            ) && (
              <h1 className="font-medium text-[17px] leading-relaxed">
                {`We are currently processing the client's Eligibility. Please bear
            with us as we are working on it.
             Kindly check back later to see if your request has been
            approved. Thank you`}
              </h1>
            )}
            <Button
              onClick={handleCancelNextModel}
              className="mt-6 w-20"
              style={{ backgroundColor: "blue", color: "white" }}
            >
              Back
            </Button>
          </div>
        }
      />

      {/* device verification */}
      <YellowModel
        open={verifyDevice}
        onCancel={handleCancelNextModel}
        content={
          <DeviceVerification
            onFinish={onFinish}
            scannedCode={scannedCode}
            newSearch={newSearch}
            handleChange={handleChange}
            getCode={getCode}
            selectedCart={selectedCart}
            handleCancelNextModel={handleCancelNextModel}
            handleUploadDeviceToYellowTenant={handleUploadDeviceToYellowTenant}
          />
        }
      />

      {/* Guidance */}
      <Modal
        open={makePayment}
        footer={null}
        onCancel={handleCancelNextModel}
        width={900}
      >
        <div className="relative">
          <div className="flex items-center justify-center mx-auto">
            <h1 className="font-extrabold pb-6 text-[20px] leading-relaxed">
              Guidance
            </h1>
          </div>
          <LockingSteps
            selectedCart={selectedCart}
            onCancel={handleCancelNextModel}
          />
        </div>
      </Modal>

      {/* upload IBM model */}
      <YellowModel
        open={uploadIBM}
        width={600}
        content={
          <UploadEBMData
            selectedCart={selectedCart}
            setInvoiceNumber={setInvoiceNumber}
            setPictures={setPictures}
            onCancel={handleCancelNextModel}
            handleSendIBMReceipt={handleSendIBMReceipt}
          />
        }
      />

      {/* device replacement */}
      <YellowModel
        open={replacement}
        content={
          <ReplaceDevice
            onFinish={onFinish}
            scannedCode={scannedCode}
            newSearch={newSearch}
            handleChange={handleChange}
            getCode={getCode}
            selectedCart={selectedCart}
            handleCancelNextModel={handleCancelNextModel}
            handleReplaceDevice={handleReplaceDevice}
          />
        }
      />

      {/* device with issues */}
      <YellowModel
        open={deviceHasIssie}
        onCancel={handleCancelNextModel}
        content={
          <div>
            <div className="flex items-center mx-auto justify-center">
              <img src={helpDeskImg} alt="" className="w-80 xh-10" />
            </div>
            <h1 className="font-medium text-[16px] leading-relaxed">
              We're pleased to inform you that{" "}
              <span className="font-bold text-[17px] leading-relaxed">
                our support team will be contacting you shortly
              </span>{" "}
              to discuss your issue in detail. Your patience is greatly
              appreciated as we work towards resolving this matter. Thanks
            </h1>
          </div>
        }
      />
    </>
  );
};

export default CartCardGridPOSWithSub;
