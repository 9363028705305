/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useRef, ReactInstance } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReloadOutlined } from "@ant-design/icons";
import {
  handleNextStep,
  handlePreviousStep,
} from "../../../store/layout/actions";
import Aos from "aos";
import "aos/dist/aos.css";
import { Button, Checkbox, notification, Radio, Divider } from "antd";
import { LinearProgress } from "@mui/material";
import Signature from "./Signature";
import Modal from "@mui/material/Modal";
import OtpInput from "react-otp-input";
import { createOtpAction, validateOtpAction } from "../../../store/otp/actions";
import { useReactToPrint } from "react-to-print";
import { customerSignatureAction } from "../../../store/pos/actions";
import {
  getShopStockCartAction,
  updateShopStockCartDetailsAction,
} from "../../../store/wareHouse/actions";
import SignatureCanvas from "react-signature-canvas";
import { useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import {
  createConsentAction,
  updateConsentAction,
} from "../../../store/posYellow/actions";
import SwitchButton from "../../buttons/SwitchButton";
const plainOptions = ["Yes", "No"];
const plainOptionsKiny = ["Yego", "Oya"];

const ConsentAgreementPage = (props: any) => {
  const { wareHouse, customer, posYelllow } = useSelector(
    (state: any) => state
  );
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const [openOtp, setOpenOtp] = React.useState(false);
  const handleCloseOtp = () => setOpenOtp(false);

  const [otp, setOtp] = React.useState<any>("");
  const templateRef = useRef<ReactInstance>(null);
  const [sign, setSign] = useState<SignatureCanvas | null>(null);
  const { auth, pos } = useSelector((state: any) => state);
  const { cartId } = useParams();
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<any>("");
  const [checked, setChecked] = useState(false);
  const [checkedList, setCheckedList] = useState(null);
  const [checkedListQuestion1, setCheckedListQuestion1] = useState(null);
  const [checkedListQuestion2, setCheckedListQuestion2] = useState(null);

  const handlePrevious = async () => {
    await handlePreviousStep(2)(dispatch);
  };

  const handleOpenOtp = async () => {
    await createOtpAction(wareHouse?.createdCart?.data?.customer?._id, {
      actionType: "customerOtp",
    })(dispatch);
    setOpenOtp(true);
  };

  const handleResend = () => {
    createOtpAction(wareHouse?.createdCart?.data?.customer?._id, {
      actionType: "resend",
      failedNumber: selectedPhoneNumber,
    })(dispatch);
  };

  const handleChange = (newValue: any) => {
    setOtp(newValue);
  };

  const handleClear = () => {
    sign?.clear();
  };

  const handlePrint = useReactToPrint({
    content: () => templateRef.current,
    pageStyle: `
        @media print {
          @page {
            size: 58mm auto;
            margin: 0;
          }
        }
        `,
  });

  const handleRadioChange = (event: any) => {
    setSelectedPhoneNumber(event.target.value);
  };

  const handleConfirmOtp = async () => {
    const selectedPhoneNumbersArray = [selectedPhoneNumber].map(String);
    const parsedOtp = parseInt(otp, 10);
    if (otp && selectedPhoneNumber) {
      try {
        const response = validateOtpAction(
          parsedOtp,
          selectedPhoneNumbersArray
        )(dispatch);
      } catch (error) {
        console.error("Error validating OTP:", error);
      }
    } else {
      console.error("OTP or selected phone number is missing");
    }
    // await createOtpAction(wareHouse?.createdCart?.data?.customer?.phone)(dispatch);
    // await handleClose();
    // await handleNextStep(1)(dispatch);
  };

  useEffect(() => {
    Aos.init();
  }, []);

  const onChange = (e: any) => {
    setChecked(e.target.checked);
  };

  const handleNext = async () => {
    const payload = {
      customer: wareHouse?.createdCart?.data?.customer?._id,
      personalDataStorageAndProcessing: checkedList,
      contactConsent: checkedListQuestion1,
      dataDisclosureToThirdParties: checkedListQuestion2,
    };
    if (
      checkedList === null ||
      checkedListQuestion1 === null ||
      checkedListQuestion2 === null
    ) {
      return notification.error({ message: "Please Answer All Questions" });
    }

    if (!pos?.customerSignature?.data?.secure_url) {
      return notification.error({ message: "Costmer didn't sign, Try again" });
    }

    if (wareHouse?.createdCart?.data?.yellowLegalAgreementDoc?._id) {
      await updateConsentAction(
        auth?.token,
        wareHouse?.createdCart?.data?.yellowLegalAgreementDoc?._id,
        payload
      )(dispatch);
      await updateShopStockCartDetailsAction(auth?.token, cartId, {
        customerSignature: pos?.customerSignature?.data?.secure_url,
        financer: "Yellow",
      })(dispatch);
      await handleNextStep(2)(dispatch);
    } else {
      await createConsentAction(auth?.token, payload)(dispatch);
      await updateShopStockCartDetailsAction(auth?.token, cartId, {
        customerSignature: pos?.customerSignature?.data?.secure_url,
        financer: "Yellow",
      })(dispatch);
      await handleNextStep(2)(dispatch);
    }
  };

  const onChangeCheckboxQuesttion1 = (e: any) => {
    setCheckedList(e.target.value);
  };

  const onChangeCheckboxQuesttion2 = (e: any) => {
    setCheckedListQuestion1(e.target.value);
  };

  const onChangeCheckboxQuesttion3 = (e: any) => {
    setCheckedListQuestion2(e.target.value);
  };

  const acceptAllRequest = () => {
    if (
      (checkedList === "Yes" || checkedList === "Yego") &&
      (checkedListQuestion1 === "Yes" || checkedListQuestion1 === "Yego") &&
      (checkedListQuestion2 === "Yes" || checkedListQuestion2 === "Yego")
    ) {
      return true;
    } else {
      return false;
    }
  };

  const [activeTab, setActiveTab] = useState(1);
  const handleTabClick = (tabNumber: number) => {
    setActiveTab(tabNumber);
  };

  useEffect(() => {
    if (auth?.token) {
      getShopStockCartAction(auth?.token, cartId)(dispatch);
    }
  }, [auth?.token, cartId, dispatch]);

  console.log(
    "wareHouse?.createdCart?.data",
    wareHouse?.createdCart?.data?.customerSignature
  );

  return (
    <>
      <div className="pb-5">
        <div className="flex flex-row justify-between pb-2">
          <div>Consent Agreements</div>
          <div>
            <span className="text-[#5F5AFE]">Step 3</span> of 5
          </div>
        </div>
        <LinearProgress variant="determinate" value={50} />
      </div>
      <div className="w-full flex flex-col h-full overflow-y-auto relative space-y-4 px-6 text-[16px] leading-relaxed">
        <div>
          <center className="xpy-10 px-10 font-medium uppercase">
            {`${activeTab === 1 ? "CONSENT FORM" : "IBIKORWA BIKURIKIRA"}`}
          </center>
        </div>
        <div>
          <SwitchButton
            activeTab={activeTab}
            handleTabClick={handleTabClick}
            label1={"English"}
            label2={"Kinyarwanda"}
          />
        </div>
        <div className="pt-6">
          {activeTab === 1 ? (
            <p>
              {`I, ${wareHouse?.createdCart?.data?.customer?.name}, residing at ${wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address?.sector} Sector, ${wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address?.district} District, ${wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address?.provence}, ${wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address?.country}, with a phone number of ${wareHouse?.createdCart?.data?.customer?.phone} and national ID ${wareHouse?.createdCart?.data?.customer?.nationalId}, grant permission and give my consent to Yellow/Samphone (the "releasee") for the use of my details as identified below under any legal use`}
            </p>
          ) : (
            <p>{`Njyewe,  ${wareHouse?.createdCart?.data?.customer?.name}, utuye mu Murenge wa ${wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address?.sector}, Akarere ka ${wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address?.district}, ${wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address?.provence} - ${wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address?.country}, ufite nimero ya terefone ${wareHouse?.createdCart?.data?.customer?.phone} n’indangamuntu no ${wareHouse?.createdCart?.data?.customer?.nationalId}, mpaye uruhushya n'uburenganzira Yellow na Samphone kugira ngo bakoreshe amakuru yanjye.`}</p>
          )}
        </div>
        {/* <Divider /> */}
        <div className="pt-14">
          <div className="flex">
            <span className="pr-2">I.</span>
            {activeTab === 1 ? (
              <p>
                I hereby consent for my personal data, including ID, photos,
                contact, transactional and financial data to be stored by Yellow
                on their cloud systems and processed to offer device products,
                offer other products and provide services to me
              </p>
            ) : (
              <p>
                Ndemeza yuko imyirondoro yanjye bwite, nimero y'indangamuntu,
                amafoto, nimero ya telefone zange, amakuru y'ubwishyu n’
                ibyimari, kubikwa na Yellow na Samphone kuri sisitemu yabo ya
                cloud kandi bigatunganywa kugira hatangwe ibicuruzwa
                byikoranabuhanga n’ ibindi bicuruzwa mukumpa serivisi
              </p>
            )}
          </div>
          <div className="pt-4 pl-4">
            <Radio.Group
              options={activeTab === 1 ? plainOptions : plainOptionsKiny}
              onChange={onChangeCheckboxQuesttion1}
              size="large"
            />
          </div>
        </div>
        <div className="pt-6">
          <div className="flex">
            <span className="pr-2">II.</span>
            {activeTab === 1 ? (
              <p>
                I herby consent to Yellow contacting me about my product and
                other products and services
              </p>
            ) : (
              <p>
                Ndemezako Yellow yampamagara kubijyana ni ibicuruzwa byanjye
                nibindi bicuruzwa na serivisi
              </p>
            )}
          </div>
          <div className="pt-4 pl-4">
            <Radio.Group
              options={activeTab === 1 ? plainOptions : plainOptionsKiny}
              onChange={onChangeCheckboxQuesttion2}
              size="large"
            />
          </div>
        </div>
        <div className="pt-6">
          <div className="flex">
            <span className="pr-2">III.</span>
            {activeTab === 1 ? (
              <p>
                I hereby grant Yellow the authority to disclose my personal
                biodata and financial information to third parties for the
                purpose of assessing credit risk. This information may be
                utilized for precise identification purposes. I also authorize
                these third parties to access my current and future credit
                information. I consent to the utilization of the aforementioned
                data and credit reports, generated by third parties utilizing
                this data, by authorized Financial Institutions for the purposes
                of identity verification and comprehensive credit risk
                management, in accordance with the data protection laws of
                Rwanda.
              </p>
            ) : (
              <p>
                Mpaye Yellow uburenganzira bwo kumenyekanisha biodata yanjye
                bwite namakuru yimari kubandi bantu hagamijwe gusuzuma ingaruka
                zinguzanyo. Aya makuru arashobora gukoreshwa muburyo bwo kumenya
                neza. Nemereye kandi abandi bantu kubona amakuru yinguzanyo
                zanjye hamwe nigihe kizaza. Nemeye gukoresha amakuru na raporo
                y'inguzanyo bimaze kuvugwa, byakozwe n’abandi bantu bakoresha
                aya makuru, n’ibigo by’imari byemewe kugira ngo hagenzurwe
                indangamuntu no gucunga neza inguzanyo, nkurikije amategeko
                arengera amakuru y’u Rwanda.
              </p>
            )}
          </div>
          <div className="pt-4 pl-4">
            <Radio.Group
              options={activeTab === 1 ? plainOptions : plainOptionsKiny}
              onChange={onChangeCheckboxQuesttion3}
              size="large"
            />
          </div>
        </div>
      </div>

      <div className="py-10 px-5">
        <Checkbox
          checked={checked}
          onChange={onChange}
          disabled={!acceptAllRequest()}
        >
          {`${activeTab === 1 ? "I agree" : "Ndabyemeye "}`}
        </Checkbox>
      </div>

      <div className="py-6 px-[35%] flex flex-row gap-4">
        <LoadingButton
          variant="outlined"
          style={{
            width: "350px",
          }}
          onClick={handlePrevious}
        >
          {`${activeTab === 1 ? "Previous" : "Subirinyuma"}`}
        </LoadingButton>
        <LoadingButton
          variant="contained"
          onClick={handleOpen}
          disabled={!checked || !acceptAllRequest() || pos?.isFetching}
          loading={
            customer?.isFetching ||
            wareHouse?.isFetching ||
            posYelllow?.isFetching
          }
          style={{
            width: "800px",
          }}
        >
          <p className="text-sm">
            {" "}
            {`${
              activeTab === 1
                ? `${
                    pos?.customerSignature?.data?.secure_url ||
                    wareHouse?.createdCart?.data?.customerSignature
                      ? "Re-Sign Consent"
                      : "Sign Consent"
                  }`
                : `${
                    pos?.customerSignature?.data?.secure_url ||
                    wareHouse?.createdCart?.data?.customerSignature
                      ? "Ongera usinye"
                      : "Sinya"
                  }`
            }`}
          </p>
        </LoadingButton>
        <LoadingButton
          variant="contained"
          style={{
            width: "250px",
          }}
          onClick={handleNext}
          disabled={
            !checked ||
            !acceptAllRequest() ||
            !pos?.customerSignature?.data?.secure_url
          }
          loading={
            customer?.isFetching ||
            wareHouse?.isFetching ||
            posYelllow?.isFetching ||
            pos?.isFetching
          }
        >
          <p className="text-sm"> {`${activeTab === 1 ? "Next" : "Komeza"}`}</p>
        </LoadingButton>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="flex justify-center items-center h-full rounded-md">
          <div className="bg-white px-4 py-4 w-[40%] h-[50%] md:h-[50%] ">
            <Signature
              handleClose={handleClose}
              handlePrint={handlePrint}
              // checkSignature={checkSignature}
              handleClear={handleClear}
              setSign={setSign}
            />
          </div>
        </div>
      </Modal>

      <Modal
        open={openOtp}
        onClose={handleCloseOtp}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        title="my title"
      >
        <div className="flex justify-center items-center h-full rounded-md capitalize">
          <div className="bg-white px-4 py-4 space-y-2 w-[30%] h-auto text-black">
            <div className="flex justify-center font-poppins text-base font-medium">
              Confirm with OTP
            </div>
            <div className="flex justify-center font-poppins text-base font-medium">
              Select phone number
            </div>

            <div className="flex justify-center border border-gray-300 rounded-md p-3">
              {selectedPhoneNumber === null ? (
                <input
                  type="checkbox"
                  name="phoneNumber"
                  className="pl-4 cursor-pointer"
                  value={wareHouse?.createdCart?.data?.customer?.phone}
                  checked={
                    selectedPhoneNumber ===
                    wareHouse?.createdCart?.data?.customer?.phone
                  }
                  onChange={handleRadioChange}
                />
              ) : (
                <input
                  type="radio"
                  name="phoneNumber"
                  className="pl-4 cursor-pointer"
                  value={wareHouse?.createdCart?.data?.customer?.phone}
                  checked={
                    selectedPhoneNumber ===
                    wareHouse?.createdCart?.data?.customer?.phone
                  }
                  onChange={handleRadioChange}
                />
              )}
              {wareHouse?.createdCart &&
                parseInt(wareHouse?.createdCart?.data?.customer?.phone)}
            </div>
            {wareHouse?.createdCart?.data?.customer?.otherPhone.map(
              (item: any) => (
                <div className="flex justify-center border border-gray-300 rounded-md p-3">
                  <input
                    type="radio"
                    name="otherPhoneNumber"
                    className="pl-4 cursor-pointer"
                    value={item.tel}
                    checked={selectedPhoneNumber === item.tel}
                    onChange={handleRadioChange}
                  />
                  {item.tel}
                </div>
              )
            )}
            <div>
              <div className="flex justify-center pb-3">
                <div className="flex flex-col align-start py-4 w-[100%] h-[80px]">
                  <div className="flex flex-row  w-[100%] items-center justify-between gap-2 pb-2">
                    <div className="text-gray-300 ">
                      <OtpInput
                        inputStyle={{
                          backgroundColor: "white",
                          border: "solid",
                          width: "20%",
                        }}
                        value={otp}
                        onChange={setOtp}
                        numInputs={5}
                        renderSeparator={<span>-</span>}
                        renderInput={(props) => <input {...props} />}
                      />{" "}
                    </div>
                    <button
                      className="text-[#5F5AFE] cursor-pointer items-center  flex flex-row"
                      onClick={handleResend}
                    >
                      <div>
                        <ReloadOutlined />
                      </div>
                      <div>Sendagain</div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center pt-[50px] gap-10">
              <Button
                style={{ color: "#5F5AFE", backgroundColor: "white" }}
                onClick={handleCloseOtp}
                className="w-[120px] h-[50px] text-lg"
              >
                <p>Cancel</p>
              </Button>
              <Button
                style={{ backgroundColor: "#5F5AFE", color: "white" }}
                onClick={handleConfirmOtp}
                className="w-[120px] h-[50px] text-lg"
              >
                <p>Confirm</p>
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ConsentAgreementPage;
