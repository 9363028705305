import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const createOtpService = async (id: any, actionType: any) => {
  return await HttpRequest.post(`${SERVER_URL}/otp/create/${id}`, actionType);
};

export const validateOtpService = async (code: any,phoneNumber:any) => {
  return HttpRequest.post(`${SERVER_URL}/otp`,{
    code,phoneNumber
});
};
