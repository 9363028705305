import { notification } from "antd";
import { myAccounts } from ".";
import { createOtpService, validateOtpService } from "./services";
import { Code39Reader } from "@zxing/library";
import { handleNextStep } from "../layout/actions";

export const createOtpAction = (id: any, actionType: any) => {
  return async (dispatch: any) => {
    try {

      dispatch(myAccounts.setIsFetching(true));
      const res = await createOtpService(id, actionType);
      if (res?.status === 201) {
        await dispatch(myAccounts.setNew(res));
        await dispatch(myAccounts.setIsFetching(false));
        handleNextStep(1);
        return res
      }
      dispatch(myAccounts.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};


export const validateOtpAction = (code: any, selectedPhoneNumber: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myAccounts.setIsFetching(true));
      const res = await validateOtpService(code, selectedPhoneNumber);
      if (res?.status === 200) {
        await dispatch(myAccounts.setNew(res));
        await dispatch(myAccounts.setIsFetching(false));
        return res
      }
      dispatch(myAccounts.setIsFetching(false));
    } catch (err) {
      console.log(err);
      if (typeof err === 'string' || err instanceof Error) {
        notification.error({
          message: 'Error',
          description: err.toString(),
        });
      } else {
        notification.error({
          message: 'Error',
          description: 'An error occurred',
        });
      }
    }
  };
};


