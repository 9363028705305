import { Button, Carousel, Divider, Form, Image, Modal } from "antd";
import SearchBar from "../../../components/SearchBar";
import { LoadingButton } from "@mui/lab";
import { getAllShopStockAction } from "../../../store/wareHouse/actions";
import { useDispatch, useSelector } from "react-redux";
import { getActivePrice, getActiveShop } from "../../../utils/converter";
import BarcodeScannerModal from "../../../components/BarcodeScannerModal";
import { useEffect, useState } from "react";
import { myWareHouseActions } from "../../../store/wareHouse";
import InfoIcon from "@mui/icons-material/Info";
import { ReactComponent as SearchItemImage } from "../../../assets/images/Scan to sell Option 2.svg";
import PamentModeDrower from "../../../components/Modals/PamentModeDrower";
import "./style.css";
import { IoPersonSharp } from "react-icons/io5";
import { IoIosCall } from "react-icons/io";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { handleNextStep } from "../../../store/layout/actions";
import TermsAndConditionForm from "../../../components/forms/TermsAndConditionForm";
import notify from "../../../assets/icons/notify.png";

const POSItemSearch = (props: any) => {
  const { auth, wareHouse, appSettings, channel } = useSelector(
    (state: any) => state
  );

  const [scannedCode, setScannedCode] = useState<string>();
  const [showItemSearch, setShowItemSearch] = useState<any>(true);

  const [newSearch, setNewSearch] = useState<boolean>(false);
  const dispatch = useDispatch();
  const productData = wareHouse?.searchResult;

  const toggleItemSearch = () => {
    setShowItemSearch(!showItemSearch);
  };

  const isFound =
    wareHouse?.searchResult?.status === 200 &&
    wareHouse?.searchResult?.data?.isInShop &&
    wareHouse?.searchResult?.data?.product?.status !== "sold";

  const onFinish = async (values: any) => {
    auth?.token &&
      (await getAllShopStockAction(
        auth?.token,
        `?serialNumber=${values?.query}&shopId=${
          getActiveShop(auth?.user?.shop?.assigned)[
            appSettings?.selectedShopNumber
          ]?.shop?._id ?? auth?.user?.shop?.shopId
        }`
      )(dispatch));
  };

  const getCode = (code: string) => {
    setNewSearch(false);
    onFinish({ query: code });
    setScannedCode(code);
  };
  const handleChange = (e: any) => {
    setNewSearch(true);
  };

  const discardSearchResult = () => {
    dispatch(myWareHouseActions.setSearchResult(null));
  };

  const itemPrice = getActivePrice(
    productData?.data?.product?.requestedItem?.product?.prices
  );
  const hasSubscription =
    productData?.data?.product?.requestedItem?.product?.subscriptionPlan
      ?.length > 0;
  const isSubscripitonPage =
    wareHouse?.createdCart?.data?.isSubscription === "subscription";

  const isCartEmpty = wareHouse?.createdCart?.data?.list?.length < 1;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (wareHouse?.createdCart?.data?.isSubscription === "") {
      setOpen(true);
    } else {
      setOpen(true);
    }
  }, [wareHouse?.createdCart?.data?.isSubscription]);

  const channelId = auth?.user?.shop?.channelId || auth?.user?.shop?.channel;

  const handlePrevious = () => {
    handleNextStep(2)(dispatch);
  };

  const [termsAndConditionModalOpen, setTermsAndConditionModalOpen] =
    useState(false);

  const onCloseTermAndContions = () => {
    setTermsAndConditionModalOpen(false);
  };

  console.log("here again", wareHouse?.createdCart?.data?.yellowReason);
  return (
    <>
      {wareHouse?.createdCart?.data?.isSubscription === "" && (
        <PamentModeDrower
          showModal={
            channelId === process.env.REACT_APP_Save_The_Children_Channel_ID
              ? true
              : false
          }
        />
      )}

      {wareHouse?.createdCart?.data?.status === "inquiry" ? (
        <div className="container rounded-lg">
          <div className="flex flex-col box box-inner justify-between ">
            {!isFound && (
              <div className="flex flex-col">
                <div className="flex justify-center gap-x-2">
                  <Form onFinish={onFinish}>
                    <Form.Item
                      name="query"
                      className="rounded-lg"
                      initialValue={scannedCode}
                    >
                      <SearchBar
                        isLoading={wareHouse.isFetching}
                        scannedCode={newSearch ? "" : scannedCode}
                        onChange={handleChange}
                        isDisabled={!isCartEmpty && isSubscripitonPage}
                      />
                    </Form.Item>
                    <div className="py-1">
                      {!wareHouse?.isFetching &&
                        wareHouse?.searchResult?.status === 200 &&
                        wareHouse?.searchResult?.data?.isInShop &&
                        wareHouse?.searchResult?.data?.product?.status !==
                          "sold" && (
                          <p className="font-semibold text-green-500">
                            Product found
                          </p>
                        )}
                      {!wareHouse?.isFetching &&
                        wareHouse?.searchError?.status === 404 && (
                          <p className="font-semibold text-red-500">
                            Product not found
                          </p>
                        )}
                      {!wareHouse?.isFetching &&
                        !wareHouse?.searchResult?.data?.isInShop && (
                          <div className="font-semibold text-yellow-500">
                            {wareHouse?.searchResult?.message}
                          </div>
                        )}
                      {!wareHouse?.isFetching &&
                        wareHouse?.searchResult?.data?.isInShop &&
                        wareHouse?.searchResult?.data?.product?.status ===
                          "sold" && (
                          <div className="font-semibold text-yellow-500">
                            The product is sold
                          </div>
                        )}
                    </div>
                  </Form>
                  <BarcodeScannerModal getCode={getCode} />
                </div>
                <div className="flex justify-center">
                  <SearchItemImage className="h-44" />
                </div>
              </div>
            )}

            {isFound && (
              <div className="">
                <div className="flex gap-2 p-3 pb-4 30px text-[1vw]">
                  <div style={{ width: "7rem" }}>
                    <Carousel autoplay>
                      {productData?.data?.product?.requestedItem?.product?.product?.images?.map(
                        (im: any) => (
                          <div>
                            <img src={im} alt="" />
                          </div>
                        )
                      )}
                    </Carousel>
                  </div>
                  <div className="flex gap-x-4">
                    <div className="flex flex-col gap-y-1">
                      <p className="text-[1.2vw] font-bold">
                        {
                          productData?.data?.product?.requestedItem?.product
                            ?.product?.model
                        }
                      </p>
                      <p>
                        {
                          productData?.data?.product?.requestedItem?.product
                            ?.product?.type
                        }
                      </p>

                      <div className="flex flex-col gap-2">
                        {productData?.data?.product?.requestedItem?.product?.specification?.map(
                          (item: string, _index: number) => (
                            <span className="text-[1vw]">
                              {item[0]}:<b>{item[1]}</b>
                            </span>
                          )
                        )}
                      </div>
                    </div>

                    <div className="mt-3 pt-2">
                      <p>
                        <b>Extended Warranty :</b>{" "}
                        {`${productData?.data?.product?.requestedItem?.product?.extendedWarranty}%`}
                      </p>
                      <p>
                        <b>In Shop :</b>
                        {productData?.data?.isInShop ? "Yes" : "No"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="absolute bottom-1 w-full flex gap-x-1 !box-border px-2">
                  <p className="flex items-center">
                    <InfoIcon /> This device is eligible for Extended Warranty.
                  </p>
                  <p className="flex-1 text-right text-[1.4vw] font-bold">{`RWF ${itemPrice?.toLocaleString()}`}</p>
                </div>
              </div>
            )}

            {isFound && (
              <div className="absolute bottom-2 right-2 flex items-center gap-4 pt-10">
                {!hasSubscription && isSubscripitonPage && (
                  <div className="text-yellow-500">
                    This model does not support subscription at them moment,
                    please try a different one!
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="box overlay rounded-sm ">
            <h1 className="text-red-500  text-center uppercase lg:max-xl:text-sm">
              {" "}
              Fill missing Information
            </h1>
            <p className="p-4 lg:max-xl:text-sm">
              {wareHouse?.createdCart?.data?.comment}
            </p>
          </div>
        </div>
      ) : (
        <>
          {wareHouse?.createdCart?.data?.yellowStatus?.toLowerCase() ===
          "pending_resubmission" ? (
            <div className="flex flex-col justify-between h-[calc(100%-50px)] rounded-lg">
              <div className="flex justify-center pb-3">
                <div className="max-w-xl p-6 bg-transparent flex space-x-4">
                  <img src={notify} alt="" className="h-10 w-10" />
                  <div>
                    <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900">
                      Missing / Incorrect Data
                    </h5>
                    <p className="mb-3 font-normal text-gray-900 leading-6">
                      {wareHouse?.createdCart?.data?.yellowReason}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col justify-between h-[calc(100%-50px)] rounded-lg xbg-red-100">
              {!isFound && (
                <div className="flex flex-col">
                  <div className="flex justify-center gap-x-2">
                    <Form onFinish={onFinish}>
                      <Form.Item
                        name="query"
                        className="rounded-lg"
                        // label="Enter serial number"
                        initialValue={scannedCode}
                      >
                        <SearchBar
                          isLoading={wareHouse.isFetching}
                          scannedCode={newSearch ? "" : scannedCode}
                          onChange={handleChange}
                          isDisabled={
                            (!isCartEmpty && isSubscripitonPage) ||
                            wareHouse?.createdCart?.data?.list?.length !== 0
                            //  ||
                            // wareHouse?.createdCart?.data?.yellowStatus?.toLowerCase() !==
                            //   "pending_asset_transfer"
                          }
                        />
                      </Form.Item>
                      <div className="py-1">
                        {!wareHouse?.isFetching &&
                          wareHouse?.searchResult?.status === 200 &&
                          wareHouse?.searchResult?.data?.isInShop &&
                          wareHouse?.searchResult?.data?.product?.status !==
                            "sold" && (
                            <p className="font-semibold text-green-500">
                              Product found
                            </p>
                          )}
                        {!wareHouse?.isFetching &&
                          wareHouse?.searchError?.status === 404 && (
                            <p className="font-semibold text-red-500">
                              Product not found
                            </p>
                          )}
                        {!wareHouse?.isFetching &&
                          !wareHouse?.searchResult?.data?.isInShop && (
                            <div className="font-semibold text-yellow-500">
                              {wareHouse?.searchResult?.message}
                            </div>
                          )}
                        {!wareHouse?.isFetching &&
                          wareHouse?.searchResult?.data?.isInShop &&
                          wareHouse?.searchResult?.data?.product?.status ===
                            "sold" && (
                            <div className="font-semibold text-yellow-500">
                              The product is sold
                            </div>
                          )}
                      </div>
                    </Form>
                    {wareHouse?.createdCart?.data?.list?.length === 0 && (
                      // wareHouse?.createdCart?.data?.yellowStatus?.toLowerCase() ===
                      //   "pending_asset_transfer" &&
                      <BarcodeScannerModal getCode={getCode} />
                    )}
                  </div>
                  <div className="flex justify-center">
                    <SearchItemImage className="h-44" />
                  </div>
                </div>
              )}

              {isFound && (
                <div className="relative flex-1 rounded-lg border border-gray-400 h-full space-y-4">
                  <div className="flex flex-row  justify-between p-3 pb-4 30px text-[1vw]">
                    <div className="flex flex-row ">
                      <div style={{ width: "7rem" }}>
                        <Carousel autoplay>
                          {productData?.data?.product?.requestedItem?.product?.product?.images?.map(
                            (im: any) => (
                              <div>
                                <img src={im} alt="" />
                              </div>
                            )
                          )}
                        </Carousel>
                      </div>
                      <div className="flex flex-col gap-y-1 space-y-4">
                        <p className="text-md font-medium">
                          {
                            productData?.data?.product?.requestedItem?.product
                              ?.product?.model
                          }
                        </p>
                        <p>
                          {
                            productData?.data?.product?.requestedItem?.product
                              ?.product?.type
                          }
                        </p>
                        <div className="flex flex-col gap-2">
                          {productData?.data?.product?.requestedItem?.product?.specification?.map(
                            (item: string, _index: number) => (
                              <span className="text-md">
                                {item[0]}:<b>{item[1]}</b>
                              </span>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex gap-x-1 px-2 pt-4">
                    <p className="flex items-center pb-2">
                      <InfoIcon /> This device is eligible for Extended
                      Warranty.
                    </p>
                    <p className="flex-1 text-right text-[1.4vw] font-bold pb-2">{`RWF ${itemPrice?.toLocaleString()}`}</p>
                  </div>
                </div>
              )}

              {isFound && (
                <>
                  <div className="absolute bottom-2 right-2 flex items-center gap-4 ">
                    {!hasSubscription && isSubscripitonPage && (
                      <div className="text-yellow-500">
                        This model does not support subscription at them moment,
                        please try a different one!
                      </div>
                    )}
                  </div>
                  <div className="flex justify-end h-[100%]  w-[100%] pt-10">
                    <div className="flex flex-row justify-around space-x-4">
                      <Button
                        className="rounded-md pb-3 text-[#5F5AFE] border border-[#5F5AFE]"
                        onClick={discardSearchResult}
                        disabled={
                          !productData?.data?.isInShop || channel?.isFetching
                        }
                        loading={channel?.isFetching}
                      >
                        DISCARD
                      </Button>
                      <Button
                        className="rounded-md pb-3 text-white bg-[#5F5AFE]"
                        onClick={props?.addToCart}
                        loading={wareHouse?.isFetching}
                        disabled={channel?.isFetching}
                      >
                        ADD TO CART
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}

          <div className="rounded-lg h-[50%]  flex flex-col xpt-8  space-y-4 overflow-y-auto xbg-green-300">
            {showItemSearch ? (
              <div className="w-[100%]  flex flex-row pt-6  justify-between px-32">
                <div className="space-y-4">
                  <div className="font-poppins font-medium text-base leading-6">
                    PERSONAL DETAILS
                  </div>
                  <div className="text-gray-500 font-poppins flex flex-row items-center gap-2">
                    <span>Names:</span>
                    <span className="text-black pl-8">
                      {wareHouse?.createdCart?.data?.customer?.name}
                    </span>
                  </div>
                  <div className="text-gray-500 font-poppins flex flex-row items-center gap-2">
                    <span>Telephone:</span>
                    <span className="text-black pl-3">
                      {`${wareHouse?.createdCart?.data?.customer?.phone} / ${
                        wareHouse?.createdCart?.data?.customer?.otherPhone?.at(
                          0
                        )?.tel ?? ""
                      }`}
                    </span>
                  </div>
                  <div className="text-gray-500 font-poppins flex flex-row items-center gap-2">
                    <span>Email:</span>
                    <span className="text-black pl-12">
                      {wareHouse?.createdCart?.data?.customer?.email}
                    </span>
                  </div>
                  <div className="text-gray-500 font-poppins flex flex-row items-center gap-2">
                    <span> National ID:</span>
                    <span className="text-black pl-3">
                      {wareHouse?.createdCart?.data?.customer?.nationalId}
                    </span>
                  </div>
                  <div className="text-gray-500 font-poppins flex flex-row items-center gap-2">
                    <span> Gender:</span>
                    <span className="text-black capitalize pl-9">
                      {wareHouse?.createdCart?.data?.customer?.gender}
                    </span>
                  </div>
                  <div className="text-gray-500 font-poppins flex flex-row items-center gap-2">
                    <span> Date of Birth:</span>
                    <span className="text-black pl-1">
                      {wareHouse?.createdCart?.data?.customer?.dateOfBirth?.slice(
                        0,
                        10
                      )}
                    </span>
                  </div>
                  <div className="space-y-4">
                    <div className="font-poppins font-medium text-base leading-6">
                      ADDRESS DETAILS
                    </div>
                    <div className="text-gray-500 font-poppins flex flex-row items-center gap-4">
                      <div>
                        <span className="font-poppins">Country : </span>
                        <span className="text-black">
                          {
                            wareHouse?.createdCart?.data?.customer
                              ?.subscriptionInfo?.address?.country
                          }
                        </span>
                      </div>
                      <div>
                        <span className="font-poppins">Sector : </span>
                        <span className="text-black">
                          {
                            wareHouse?.createdCart?.data?.customer
                              ?.subscriptionInfo?.address?.sector
                          }
                        </span>
                      </div>
                    </div>
                    <div className="text-gray-500 font-poppins flex flex-row items-center gap-4">
                      <div>
                        <span className="font-poppins">Province : </span>
                        <span className="text-black">
                          {
                            wareHouse?.createdCart?.data?.customer
                              ?.subscriptionInfo?.address?.provence
                          }
                        </span>
                      </div>
                      <div>
                        <span className="font-poppins">Cell : </span>
                        <span className="text-black">
                          {
                            wareHouse?.createdCart?.data?.customer
                              ?.subscriptionInfo?.address?.cell
                          }
                        </span>
                      </div>
                    </div>
                    <div className="text-gray-500 font-poppins flex flex-row items-center gap-4">
                      <div>
                        <span className="font-poppins">District : </span>
                        <span className="text-black">
                          {
                            wareHouse?.createdCart?.data?.customer
                              ?.subscriptionInfo?.address?.district
                          }
                        </span>
                      </div>
                      <div>
                        <span className="font-poppins">Village : </span>
                        <span className="text-black">
                          {
                            wareHouse?.createdCart?.data?.customer
                              ?.subscriptionInfo?.address?.village
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="space-y-4 mb-10">
                    <div className="font-poppins font-medium text-base leading-6">
                      FINANCIAL DETAILS
                    </div>
                    <div className=" font-poppins flex flex-row items-center gap-2">
                      <span className="text-gray-500">
                        Main Source of Income :{" "}
                      </span>
                      <span className="text-black">
                        {
                          wareHouse?.createdCart?.data?.customer
                            ?.subscriptionInfo?.mainIncomeSource
                        }
                      </span>
                    </div>
                    <div className="font-poppins flex flex-row items-center gap-2">
                      <span className="text-gray-500 ">
                        Occupation / BusinessType :{" "}
                      </span>
                      <span className="text-black">
                        {
                          wareHouse?.createdCart?.data?.customer
                            ?.subscriptionInfo?.occupationOrBusinessType
                        }
                      </span>
                    </div>
                    <div className=" font-poppins flex flex-row items-center gap-2">
                      <span className="text-gray-500">Monthly Income : </span>
                      <span className="text-black">
                        {
                          wareHouse?.createdCart?.data?.customer
                            ?.subscriptionInfo?.monthlyIncome
                        }
                      </span>
                    </div>
                    <div className=" font-poppins flex flex-row items-center gap-2">
                      <span className="text-gray-500">Income Frequency : </span>
                      <span className="text-black">
                        {
                          wareHouse?.createdCart?.data?.customer
                            ?.subscriptionInfo?.incomeFrequency
                        }
                      </span>
                    </div>
                    <div className=" font-poppins flex flex-row items-center gap-2">
                      <span className="text-gray-500">
                        Currently own a smartphone? :{" "}
                      </span>
                      <span className="text-black">
                        {
                          wareHouse?.createdCart?.data?.customer
                            ?.subscriptionInfo?.ownsSmartphone
                        }
                      </span>
                    </div>
                  </div>
                  <div className="space-y-4">
                    <div className="font-poppins font-medium text-base leading-6">
                      Documents
                    </div>
                    <div className=" font-poppins flex flex-row items-center gap-2">
                      <Image
                        width={100}
                        src={
                          wareHouse?.createdCart?.data?.customer
                            ?.subscriptionInfo?.nationalIDDoc
                        }
                      />
                      <Image
                        width={100}
                        src={
                          wareHouse?.createdCart?.data?.customer
                            ?.subscriptionInfo?.photoBackOfID
                        }
                      />
                      <Image
                        width={100}
                        src={
                          wareHouse?.createdCart?.data?.customer
                            ?.subscriptionInfo?.photoCustomerHoldingID
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="w-[100%]  flex flex-row pt-6  justify-between px-32">
                <div className="space-y-4">
                  <div className="font-poppins font-medium text-base leading-6">
                    Witness DETAILS
                  </div>
                  {wareHouse?.createdCart?.data?.customer?.subscriptionInfo.witnessInfo?.map(
                    (el: any) => (
                      <div>
                        <div className="space-y-2.5">
                          <p className="text-gray-500 font-poppins flex flex-row items-center gap-2">
                            <span>
                              <IoPersonSharp />
                            </span>
                            <span>{el.name}</span>
                          </p>
                          <p className="text-gray-500 font-poppins flex flex-row items-center gap-2">
                            <span>
                              <IoIosCall />
                            </span>
                            <span>{el.phone}</span>
                          </p>
                          <p className="text-gray-500 font-poppins flex flex-row items-center gap-2">
                            <span>
                              <IoPersonSharp />
                            </span>
                            <span>{el.relationship}</span>
                          </p>
                        </div>
                        <Divider />
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
            <div
              className="cursor-pointer flex justify-between items-center pt-10"
              onClick={toggleItemSearch}
            >
              <div></div>
              <IoIosArrowDropdownCircle
                className={`bg-[#5F5AFE] text-white w-10 h-8 rounded-md ${
                  !showItemSearch ? "rotate-180" : " "
                }`}
              />
              {!wareHouse?.createdCart?.data?.isSubscriptionSubmitted ||
              wareHouse?.createdCart?.data?.yellowStatus?.toLowerCase() ===
                "pending_resubmission" ? (
                <div className="flex justify-end space-x-4">
                  <LoadingButton
                    variant="outlined"
                    style={{
                      width: "150px",
                    }}
                    onClick={handlePrevious}
                  >
                    {`Previous`}
                  </LoadingButton>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </>
      )}

      <Modal
        centered
        open={termsAndConditionModalOpen}
        footer={null}
        onCancel={onCloseTermAndContions}
        width={1000}
      >
        <TermsAndConditionForm onCancel={onCloseTermAndContions} />
      </Modal>
    </>
  );
};

export default POSItemSearch;
