import React from "react";
import lockedDeviceYes from "../../../assets/images/locked.jpg";
import { Radio, Input } from "antd";

const { TextArea } = Input;

const plainOptions = ["Yes", "No"];

const VaultEnabled = (props: any) => {
  return (
    <>
      <div className="flex items-center flex-col h-[36rem] overflow-y-auto">
        <img
          src={lockedDeviceYes}
          alt=""
          className="w-96 h-[450px] object-cover"
        />
        <div className="pt-10">
          <h1 className="capitalize font-medium text-[20px] leading-relaxed">
            Is Vault Enabled?
          </h1>

          <div className="pt-4 pl-4">
            <Radio.Group
              options={plainOptions}
              onChange={(e: any) => props?.setAccept(e.target.value)}
              size="large"
            />
          </div>
        </div>

        {props?.accept === "No" && (
          <TextArea
            style={{ width: 500, marginTop: 10 }}
            rows={4}
            placeholder="Explain the device's issue"
            allowClear
            onChange={(e: any) => props?.setComment(e.target.value)}
          />
        )}
      </div>
    </>
  );
};

export default VaultEnabled;
