import React from "react";
import InsertSim from "../../../assets/images/insertSim.png";

const InsertSimCard = () => {
  return (
    <div className="flex items-center flex-col pt-6 h-[30rem] overflow-y-auto">
      <img src={InsertSim} alt="" className="w-96 xh-52" />
      <div className="pt-6">
        <h1 className="capitalize font-medium text-[20px] leading-relaxed">
          inser SIM card
        </h1>
        <p className="capitalize font-medium text-[14px] leading-relaxed">
          Open the box, insert MTN sim card then turn on device
        </p>
      </div>
    </div>
  );
};

export default InsertSimCard;
