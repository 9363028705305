import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import { listManages } from "../../assets/data/pages";
import { useDispatch, useSelector } from "react-redux";
import { Divider } from "antd";
import ProfileButton from "./ProfileButton";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { authActions } from "../../store/auth";
import { myShopActions } from "../../store/shop";
import SwitchShopButton from "./SwitchShopButton";
import { getActiveShop, handleClearAllState } from "../../utils/converter";
import { myAppSettings } from "../../store/appSettings";
import { myChannelActions } from "../../store/channel";
import { mySupplierActions } from "../../store/supplier";
import { myWareHouseOutActions } from "../../store/wareHouseOut";
import { myWareHouseActions } from "../../store/wareHouse";
import { myTransferActions } from "../../store/transfer";
import { myTermsAndConditionsActions } from "../../store/TermsAndCondirons";
import { myEmployeeActions } from "../../store/setting";
import { myRepairActions } from "../../store/repair";
import { myProductActions } from "../../store/product";
import { myPosYelloActions } from "../../store/posYellow";
import { myPosOverViewActions } from "../../store/posOverView";
import { myPosActions } from "../../store/pos";
import { myKPIActions } from "../../store/kpi";
import { myCustomerActions } from "../../store/customer";

const IconMenu: React.FC<{
  selected?: number;
  isOpen?: boolean;
  setIsOpen?: (sate: boolean) => void;
}> = ({ selected }) => {
  const { auth } = useSelector((state: any) => state);
  const [selectedTabIndex, setSelectedTabIndex] = useState(selected);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const hasMoreShops = getActiveShop(auth?.user?.shop?.assigned)?.length > 1;

  const clearActions = [
    mySupplierActions.setAll,
    mySupplierActions.setAttributes,
    mySupplierActions.setQuery,
    mySupplierActions.setNew,
    mySupplierActions.setUpdated,
    mySupplierActions.setSelected,
    myWareHouseOutActions.setAll,
    myWareHouseOutActions.setAttributes,
    myWareHouseOutActions.setQuery,
    myWareHouseOutActions.setNew,
    myWareHouseOutActions.setSelected,
    myWareHouseOutActions.setSelectedDeliveryNote,
    myWareHouseOutActions.setFilters,
    myWareHouseOutActions.setAllStockOut,
    myWareHouseOutActions.setSelectedRequest,
    myWareHouseOutActions.setOne,
    myWareHouseOutActions.setWarehouseItem,
    myWareHouseOutActions.setWarehouseOut,
    myWareHouseOutActions.setWarehouseOutItem,
    myWareHouseOutActions.setWarehouseOutSelected,
    myWareHouseOutActions.setWarehouseSelected,
    myWareHouseActions.setAll,
    myWareHouseActions.setAttributes,
    myWareHouseActions.setQuery,
    myWareHouseActions.setNew,
    myWareHouseActions.setSelected,
    myWareHouseActions.setSelectedDeliveryNote,
    myWareHouseActions.setFilters,
    myWareHouseActions.setAllStockStats,
    myWareHouseActions.setAllStockProductStats,
    myWareHouseActions.setSearchResult,
    myWareHouseActions.setSearchError,
    myWareHouseActions.setCreatedCart,
    myTransferActions.setAll,
    myTransferActions.setAttributes,
    myTransferActions.setQuery,
    myTransferActions.setNew,
    myTransferActions.setUpdated,
    myTransferActions.setSelected,
    myTransferActions.setShopTo,
    myTransferActions.setShopProducts,
    myTransferActions.setShopStock,
    myTermsAndConditionsActions.setAll,
    myShopActions.setAll,
    myShopActions.setSelected,
    myShopActions.setAgents,
    myShopActions.setAllSales,
    myShopActions.setSelectedRequestedStock,
    myShopActions.setMyShop,
    myEmployeeActions.setAll,
    myEmployeeActions.setAttributes,
    myEmployeeActions.setQuery,
    myEmployeeActions.setNew,
    myEmployeeActions.setUpdated,
    myEmployeeActions.setSelected,
    myEmployeeActions.setAgentTransaction,
    myEmployeeActions.setAgent,
    myRepairActions.setRepairSearchResult,
    myRepairActions.setRepairSearchError,
    myRepairActions.setRepairRequest,
    myRepairActions.setAll,
    myRepairActions.setSelected,
    myRepairActions.setSelectedRepair,
    myProductActions.setAll,
    myProductActions.setAttributes,
    myProductActions.setQuery,
    myProductActions.setNew,
    myProductActions.setSelected,
    myProductActions.setSelectedProduct,
    myProductActions.setProductStore,
    myProductActions.setStoredProduct,
    myPosYelloActions.setAll,
    myPosYelloActions.setAttributes,
    myPosYelloActions.setQuery,
    myPosYelloActions.setNew,
    myPosYelloActions.setUpdated,
    myPosYelloActions.setSelected,
    myPosOverViewActions.setAll,
    myPosOverViewActions.setAttributes,
    myPosOverViewActions.setQuery,
    myPosOverViewActions.setNew,
    myPosOverViewActions.setUpdated,
    myPosOverViewActions.setSelected,
    myPosOverViewActions.setSamphonePaymentStats,
    myPosOverViewActions.setMTNPaymentStats,
    myPosOverViewActions.setOtherPaymentStats,
    myPosOverViewActions.setTodayRevenueStats,
    myPosOverViewActions.setOverviewStats,
    myPosActions.setAll,
    myPosActions.setAttributes,
    myPosActions.setQuery,
    myPosActions.setNew,
    myPosActions.setUpdated,
    myPosActions.setSelected,
    myPosActions.setStock,
    myPosActions.setStockByStatus,
    myPosActions.setTransation,
    myPosActions.setSelectedTransation,
    myPosActions.setCarts,
    myPosActions.setPriceList,
    myPosActions.setRecent,
    myPosActions.setNosale,
    myPosActions.setOnePriceListItem,
    myPosActions.setCheckoutResponseStatus,
    myPosActions.setCustomerSignature,
    myPosActions.setCustomerProfile,
    myPosActions.setDataActivated,
    myPosActions.setPaymentInitiationStatus,
    myPosActions.setPaymentDoneStatus,
    myPosActions.setCheckPaymentAgainHelper,
    myPosActions.setStatusResult,
    myPosActions.setCommisssionBalance,
    myPosActions.setShopCommission,
    myPosActions.setNationIdBack,
    myPosActions.setNationIpWithPerson,
    myKPIActions.setAll,
    myKPIActions.setAttributes,
    myKPIActions.setQuery,
    myKPIActions.setNew,
    myKPIActions.setUpdated,
    myKPIActions.setSelected,
    myChannelActions.setAll,
    myChannelActions.setAttributes,
    myChannelActions.setQuery,
    myChannelActions.setNew,
    myChannelActions.setUpdated,
    myChannelActions.setSelected,
    myChannelActions.setAgents,
    myChannelActions.setShops,
    myChannelActions.setPriceList,
    myChannelActions.setPriceListItems,
    myChannelActions.setRequestedStock,
    myChannelActions.setRfsSelected,
    myChannelActions.setProducts,
    myChannelActions.setPriceListItemToUpdate,
    myChannelActions.setPriceListSearch,
    myChannelActions.setRfsTransfer,
    myChannelActions.setSelectedRequestedStock,
    myChannelActions.setShopStockOut,
    myChannelActions.setWarehouseOut,
    myChannelActions.setWarehouseOutSelected,
    myChannelActions.setWarehouseOutItem,
    myChannelActions.setPendingShopOut,
    myCustomerActions.setAll,
    myCustomerActions.setAttributes,
    myCustomerActions.setQuery,
    myCustomerActions.setNew,
    myCustomerActions.setUpdated,
    myCustomerActions.setSelected,
    myCustomerActions.setSearchResult,
    myCustomerActions.setPurchased,
    myCustomerActions.setCustomerRepair,
  ];

  return (
    <div
      className={`flex flex-col justify-between items-center bg-white h-full  pt-4 rounded-tr-lg rounded-br-lg z-50`}
    >
      <div className="flex flex-col items-center">
        {listManages.map((manage: any, index: number) => (
          <>
            <li
              className={`flex justify-between items-center border rounded-lg mt-2 w-full mx-2 ${
                selectedTabIndex === index ? "bg-white" : ""
              } hover:text-blue-500 hover:bg-white space-x-5`}
              onClick={() => {
                setSelectedTabIndex(index);
                navigate(manage.path);
              }}
            >
              <div
                className={`SideNav flex flex-col items-center justify-center w-full text-xs py-2 text-[#3f3f6c] ${
                  selectedTabIndex === index ? "text-blue-600" : "text-gray-500"
                }`}
              >
                {manage.icon}
              </div>
            </li>
          </>
        ))}
      </div>
      <div className="flex flex-col items-center space-y-2">
        <Divider className="!m-0" />
        {hasMoreShops && <SwitchShopButton />}
        <Divider className="!m-0" />
        <ProfileButton />
        <Divider className="!m-0" />
        <div
          className="py-2"
          onClick={() => {
            dispatch(authActions.logout());
            dispatch(myShopActions.setMyShop(null));
            dispatch(myAppSettings.setSelectedShopNumber(0));
            localStorage.removeItem("selectedShopIndex");
            handleClearAllState(dispatch, clearActions);
          }}
        >
          <ListItemIcon
            className={`SideNav flex flex-col items-center text-xs `}
          >
            <PowerSettingsNewIcon className="SamIcon" />
            {"Logout"}
          </ListItemIcon>
        </div>
      </div>
    </div>
  );
};

export default IconMenu;
