import React, { useEffect, useState } from "react";
import { Radio, notification, Table, Typography, Checkbox, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAllTermsAndConditionsAction } from "../../../store/TermsAndCondirons/actions";
import "./address.css";
import { useParams } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import { customerSignatureAction } from "../../../store/pos/actions";
import { updateShopStockCartDetailsAction } from "../../../store/wareHouse/actions";
import { Margin, usePDF } from "react-to-pdf";
import { getOneAccountAction } from "../../../store/account/actions";
import SignatureButton from "../../buttons/SignatureButton";
import { saveSignatureAndlegaLigalAgreementAction } from "../../../store/posYellow/actions";
import SwitchButton from "../../buttons/SwitchButton";

const plainOptions = ["Yes", "No"];
const plainOptionsKiny = ["Yego", "Oya"];

const YellowTermsAndConditions: React.FC<{
  templateRef: any;
  handlePrint: any;
  props?: any;
  cancel: any;
}> = ({ templateRef, handlePrint, props, cancel }) => {
  const { wareHouse, auth, pos } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const { cartId, accountId } = useParams();
  const [customerSignature, setCustomerSignature] = useState<any>([]);
  const [activeTab, setActiveTab] = useState(1);

  const [sign, setSign] = useState<SignatureCanvas | null>(null);
  const [question1, setQuestion1] = useState(null);
  const [question2, setQuestion2] = useState(null);
  const [question3, setQuestion3] = useState(null);
  const [question4, setQuestion4] = useState(null);
  const [question5, setQuestion5] = useState(null);
  const [question6, setQuestion6] = useState(null);
  const [question7, setQuestion7] = useState(null);
  const [question8, setQuestion8] = useState(null);
  const [question9, setQuestion9] = useState(null);
  const [question10, setQuestion10] = useState(null);
  const [question11, setQuestion11] = useState(null);
  const [question12, setQuestion12] = useState(null);

  const { toPDF, targetRef } = usePDF({
    filename: wareHouse?.createdCart?.data?.customer?.name
      ?.split(" ")
      ?.join("_"),
    page: { margin: Margin.MEDIUM },
  });

  const handleDownloadPDF = () => {
    toPDF({
      filename: wareHouse?.createdCart?.data?.customer?.name
        ?.split(" ")
        ?.join("_"),
      page: { margin: Margin.MEDIUM },
    });
  };

  const handleClear = () => {
    sign?.clear();
    setCustomerSignature("");
  };

  useEffect(() => {
    const submitFunction = async () => {
      if (pos?.customerSignature?.data?.secure_url) {
        await saveSignatureAndlegaLigalAgreementAction(
          auth?.token,
          wareHouse?.createdCart?.data?.yellowLegalAgreementDoc?._id,
          {
            fullPurchasePriceOnTime: question1,
            latePaymentLockout: question2,
            returnProductOnInabilityToPay: question3,
            contactingPoliceAsconsequencesOfFailureToPay: question4,
            blacklistPlacementAsconsequencesOfFailureToPay: question5,
            monthlyPaymentUnderstanding: question6,
            dataConnectivityForUnlock: question7,
            responsibilityForStolenOrLostProduct: question8,
            theftReportingRequirement: question9,
            productWarranty: question10,
            consequencesOfFraudulentUnlock: question11,
            consequencesOfSellingBeforeFullPayment: question12,
            signature: pos?.customerSignature?.data?.secure_url,
          }
        )(dispatch);
        await updateShopStockCartDetailsAction(auth?.token, cartId, {
          acceptTermsAndConditions: true,
        })(dispatch);
      }
    };
    submitFunction();
  }, [pos?.customerSignature?.data?.secure_url, dispatch, auth?.token, cartId]);

  const handleSignature = async () => {
    const trimmedCanvas = sign?.getTrimmedCanvas();
    if (trimmedCanvas) {
      const dataUrl = trimmedCanvas.toDataURL("image/png");
      if (dataUrl) {
        await customerSignatureAction(auth?.token, {
          image: dataUrl,
        })(dispatch);
      }
    }
  };

  const checkAcceptTerms = () => {
    if (
      (question1 === "Yes" || question1 === "Yego") &&
      (question2 === "Yes" || question2 === "Yego") &&
      (question3 === "Yes" || question3 === "Yego") &&
      (question4 === "Yes" || question4 === "Yego") &&
      (question5 === "Yes" || question5 === "Yego") &&
      (question6 === "Yes" || question6 === "Yego") &&
      (question7 === "Yes" || question7 === "Yego") &&
      (question8 === "Yes" || question8 === "Yego") &&
      (question9 === "Yes" || question9 === "Yego") &&
      (question10 === "Yes" || question10 === "Yego") &&
      (question11 === "Yes" || question11 === "Yego") &&
      (question12 === "Yes" || question12 === "Yego")
    ) {
      return true;
    } else {
      return false;
    }
  };

  const openNotification = () => {
    if (
      question1 === null ||
      question2 === null ||
      question3 === null ||
      question4 === null ||
      question5 === null ||
      question6 === null ||
      question7 === null ||
      question8 === null ||
      question9 === null ||
      question10 === null ||
      question11 === null ||
      question12 === null
    ) {
      return notification.error({ message: "Please Answer All Questions" });
    }
    notification.error({
      message: "Customer signature is required",
      description:
        "Please provide Customer signature to accept Terms and conditions",
    });
  };

  const checkSignature = () => {
    if (sign?.isEmpty()) {
      openNotification();
    } else {
      handleSignature();
      handleDownloadPDF();
      cancel();
    }
  };

  const { Text } = Typography;

  const columns = [
    {
      title: "Brand",
      dataIndex: "brand",
    },
    {
      title: "Model",
      dataIndex: "model",
    },
    {
      title: "Capacity",
      dataIndex: "capacity",
    },
    {
      title: "IMEI",
      dataIndex: "sirialNumber",
    },
    {
      title: "Installment Plan",
      dataIndex: "installmentPlan",
      render: (text: any, record: any) => (
        <p className="cursor-pointer">
          <span className="text-xs">{`${text} Months`}</span>
        </p>
      ),
    },
    {
      title: "Installment type",
      dataIndex: "installmentType",
      render: (text: any, record: any) => <p className="capitalize">{text}</p>,
    },
    {
      title: "Recurring Payment",
      dataIndex: "recurringPayment",
    },
  ];

  React.useEffect(() => {
    getAllTermsAndConditionsAction(auth.token, "?")(dispatch);
  }, [dispatch, auth.token]);

  const priceListId =
    wareHouse?.createdCart?.data?.list &&
    wareHouse?.createdCart?.data?.list[0]?.priceProduct?.subscriptionPlan[0]
      ?.pricelistItem;

  const dataToDisplay = wareHouse?.createdCart?.data?.list?.map((el: any) => {
    return {
      key: el._id,
      brand: el?.warehouseProduct?.product?.brand,
      model: el?.warehouseProduct?.product?.model,
      price: el?.payment?.amount?.toLocaleString(),
      sirialNumber: el?.warehouseProduct?.serialNumber,
      recurringPayment: el?.payment?.installementPayment?.toLocaleString(),
      capacity: el?.shopStockItem?.requestedItem?.product?.specification
        .filter((item: any) => item[0] === "Capacity (ROM)")
        .map((item: any) => item[1]),
      installmentType: wareHouse?.createdCart?.data?.instalmentPlan,
      installmentPlan: wareHouse?.createdCart?.data?.subscriptionPlan,
      priceListId: priceListId,
      data: el,
    };
  });

  useEffect(() => {
    getOneAccountAction(accountId as string, auth.token)(dispatch);
  }, [accountId, auth.token, dispatch]);
  const [checked, setChecked] = useState(false);
  const onChange = (e: any) => {
    setChecked(e.target.checked);
  };

  const handleTabClick = (tabNumber: number) => {
    setActiveTab(tabNumber);
  };

  return (
    <>
      <div className="px-10" ref={targetRef}>
        <SwitchButton
          activeTab={activeTab}
          handleTabClick={handleTabClick}
          label1={"English"}
          label2={"Kinyarwanda"}
        />
        <div className="flex justify-between  mt-5">
          <div>
            <h1 className="text-[#0F0F47] text-lg font-bold capitalize">
              {`${
                activeTab === 1 ? "Customer information" : "Amakuru y'umukiriya"
              }`}
            </h1>
            <ul className="">
              <li className="text-base capitalize pt-4">
                <span> Names:</span>{" "}
                <span className="pl-[72px]">
                  {wareHouse?.createdCart?.data?.customer?.name}
                </span>
              </li>
              <li className=" text-base pt-2">
                <span> Financed by:</span>{" "}
                <span className="pl-10 capitalize">Yellow</span>
              </li>
              <li className=" text-base pt-2">
                <span> Shop:</span>{" "}
                <span className="pl-[85.9px] ">
                  {wareHouse?.createdCart?.data?.shop?.name}
                </span>
              </li>
              <li className=" text-base pt-2">
                <span> Date:</span>{" "}
                <span className="pl-[91px] ">
                  {wareHouse?.createdCart?.data?.updatedAt?.substring(0, 10)}
                </span>
              </li>
            </ul>
          </div>
          <div className="mr-3 rounded-md mt-12">
            <img
              src={
                wareHouse?.createdCart?.data?.customer?.subscriptionInfo
                  ?.photoCustomerHoldingID
              }
              alt="profile"
              width={200}
              className="rounded-md"
            />
          </div>
        </div>
        <div className="">
          <h1 className="text-[#0F0F47] text-lg font-bold capitalize py-10 ">
            {`${activeTab === 1 ? "Devices Details" : "Amakuri"}`}
          </h1>
          <Table
            columns={columns}
            dataSource={dataToDisplay}
            pagination={false}
            bordered
            summary={(pageData) => {
              console.log("page data", pageData[0]?.price);
              let totalPrice = pageData && pageData[0]?.price;
              //calculate total price......
              pageData.forEach((item: any) => {
                const p = item?.p;

                if (p && p.payment && p.payment.amount) {
                  totalPrice += p.payment.amount;
                }
              });

              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell
                      index={0}
                      colSpan={5}
                      className="font-bold"
                    >
                      Total Installment Payment Amount
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} colSpan={3}>
                      <Text className="text-[18px] font-bold">
                        {totalPrice} Rfw
                      </Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />

          <div className="divide-y divide-black ">
            <h1 className="text-[20px] text-center  font-bold uppercase p-10">
              General Terms and Conditions
            </h1>
            <section className="text-base ">
              <div className="pt-6">
                <div className="flex">
                  <span className="pr-2">I.</span>
                  {activeTab === 1 ? (
                    <p>
                      I understand, I make a legal commitment to pay the full
                      purchase price on time
                    </p>
                  ) : (
                    <p>
                      Ndabyumva, niyemeje muburyo bwemewe n'amategeko kuzishyura
                      igiciro cyuzuye ku gihe
                    </p>
                  )}
                </div>
                <div className="pt-4 pl-4">
                  <Radio.Group
                    options={activeTab === 1 ? plainOptions : plainOptionsKiny}
                    onChange={(e: any) => setQuestion1(e.target.value)}
                    size="large"
                  />
                </div>
              </div>
              <div className="pt-6">
                <div className="flex">
                  <span className="pr-2">II.</span>
                  {activeTab === 1 ? (
                    <p>
                      Late on Payment? I understand, my Product will lock and I
                      will not be able to use it
                    </p>
                  ) : (
                    <p>
                      Gutinda kwishyura? ndabyumva, igicuruzwa cyange cyizafunga
                      kandi ntabwo nzaba nyishoboye kugikoresha
                    </p>
                  )}
                </div>
                <div className="pt-4 pl-4">
                  <Radio.Group
                    options={activeTab === 1 ? plainOptions : plainOptionsKiny}
                    onChange={(e: any) => setQuestion2(e.target.value)}
                    size="large"
                  />
                </div>
              </div>
              <div className="pt-6">
                <div className="flex">
                  <span className="pr-2">III.</span>
                  {activeTab === 1 ? (
                    <p>
                      Can No Longer Afford to Pay? I must return my working
                      product in original packaging to the merchant / agent I
                      purchased from
                    </p>
                  ) : (
                    <p>
                      Udashoboye kwishyura? ngomba gusubiza igicuruzwa cyange
                      gikora neza mu gipfunyika bazananye ukabisubiza umucuruzi/
                      umu agent wabiguzeho
                    </p>
                  )}
                </div>
                <div className="pt-4 pl-4">
                  <Radio.Group
                    options={activeTab === 1 ? plainOptions : plainOptionsKiny}
                    onChange={(e: any) => setQuestion3(e.target.value)}
                    size="large"
                  />
                </div>
              </div>
              <div className="pt-6">
                <div className="flex">
                  <span className="pr-2">IV.</span>
                  {activeTab === 1 ? (
                    <p>
                      Fail to Pay or Return? I understand, Yellow will contact
                      the Police and my Village Leader
                    </p>
                  ) : (
                    <p>
                      Kulephera kulipira kapena kubwerera? Ndamvetsetsa kuti
                      Yellow izaziwisa a Polisi ndi adindo a mdera langa
                    </p>
                  )}
                </div>
                <div className="pt-4 pl-4">
                  <Radio.Group
                    options={activeTab === 1 ? plainOptions : plainOptionsKiny}
                    onChange={(e: any) => setQuestion4(e.target.value)}
                    size="large"
                  />
                </div>
              </div>
              <div className="pt-6">
                <div className="flex">
                  <span className="pr-2">V.</span>
                  {activeTab === 1 ? (
                    <p>
                      Fail to Pay or Return? I understand, Yellow will place my
                      name on the Blacklist
                    </p>
                  ) : (
                    <p>
                      Kulephera kulipira kapena kubwerera? Ndikuziwa kuti Yellow
                      izayika dzina langa mu m'bukhu la anthu oletsedwa kugula
                    </p>
                  )}
                </div>
                <div className="pt-4 pl-4">
                  <Radio.Group
                    options={activeTab === 1 ? plainOptions : plainOptionsKiny}
                    onChange={(e: any) => setQuestion5(e.target.value)}
                    size="large"
                  />
                </div>
              </div>
              <div className="pt-6">
                <div className="flex">
                  <span className="pr-2">VI.</span>
                  {activeTab === 1 ? (
                    <p>
                      I understand how to make my monthly payments and I confirm
                      I can make them on my own
                    </p>
                  ) : (
                    <p>
                      Ndabyumva uburyo nzajya nishyura buri kwezi kandi ndemeza
                      ko nshobora kubikora ngenyine
                    </p>
                  )}
                </div>
                <div className="pt-4 pl-4">
                  <Radio.Group
                    options={activeTab === 1 ? plainOptions : plainOptionsKiny}
                    onChange={(e: any) => setQuestion6(e.target.value)}
                    size="large"
                  />
                </div>
              </div>
              <div className="pt-6">
                <div className="flex">
                  <span className="pr-2">VII.</span>
                  {activeTab === 1 ? (
                    <p>
                      I understand that I need to connect to data regularly to
                      keep my phone unlocked and I won't be able to unlock the
                      phone without data.
                    </p>
                  ) : (
                    <p>
                      Ndabyumva ko nkeneye gufungura data burigihe kugirango
                      terefone yange ikomeze ifunguye cyangwa sinzashobora
                      gufungura terefone nta data.
                    </p>
                  )}
                </div>
                <div className="pt-4 pl-4">
                  <Radio.Group
                    options={activeTab === 1 ? plainOptions : plainOptionsKiny}
                    onChange={(e: any) => setQuestion7(e.target.value)}
                    size="large"
                  />
                </div>
              </div>
              <div className="pt-6">
                <div className="flex">
                  <span className="pr-2">VIII.</span>
                  {activeTab === 1 ? (
                    <p>
                      Even if the product is stolen, damaged or lost I commit to
                      make all the payments on time
                    </p>
                  ) : (
                    <p>
                      Nubwo igicuruzwa cyakibwa,kikangirika cyangwa kikabura
                      ndiyemeza kuzakomeza kwishyura ku gihe
                    </p>
                  )}
                </div>
                <div className="pt-4 pl-4">
                  <Radio.Group
                    options={activeTab === 1 ? plainOptions : plainOptionsKiny}
                    onChange={(e: any) => setQuestion8(e.target.value)}
                    size="large"
                  />
                </div>
              </div>
              <div className="pt-6">
                <div className="flex">
                  <span className="pr-2">IX.</span>
                  {activeTab === 1 ? (
                    <p>
                      I understand, I must report the theft to the police and
                      Yellow within 2 days
                    </p>
                  ) : (
                    <p>
                      Ndabyumva, ngomba kumenyesha ubujura polisi na Yellow
                      mugihe cyiminsi 2
                    </p>
                  )}
                </div>
                <div className="pt-4 pl-4">
                  <Radio.Group
                    options={activeTab === 1 ? plainOptions : plainOptionsKiny}
                    onChange={(e: any) => setQuestion9(e.target.value)}
                    size="large"
                  />
                </div>
              </div>
              <div className="pt-6">
                <div className="flex">
                  <span className="pr-2">X.</span>
                  {activeTab === 1 ? (
                    <p>
                      I understand, my Product has a 12 month warranty for
                      manufacturer defects only
                    </p>
                  ) : (
                    <p>
                      Ndabyumva, ibicuruzwa byanjye bifite garanti yamezi 12
                      kubibazo byatewe nuwabikoze muruganda gusa
                    </p>
                  )}
                </div>
                <div className="pt-4 pl-4">
                  <Radio.Group
                    options={activeTab === 1 ? plainOptions : plainOptionsKiny}
                    onChange={(e: any) => setQuestion10(e.target.value)}
                    size="large"
                  />
                </div>
              </div>
              <div className="pt-6">
                <div className="flex">
                  <span className="pr-2">XI.</span>
                  {activeTab === 1 ? (
                    <p>
                      I understand if I attempt to fraudulently unlock the
                      phone, this is a Criminal Offence and reported to Police
                    </p>
                  ) : (
                    <p>
                      Ndabyumva ni ngerageza gufungura muburiganya terefone, iki
                      kizaba ari icyaha gihanirwa kandi kizamenyeshwa kuri
                      polisi
                    </p>
                  )}
                </div>
                <div className="pt-4 pl-4">
                  <Radio.Group
                    options={activeTab === 1 ? plainOptions : plainOptionsKiny}
                    onChange={(e: any) => setQuestion11(e.target.value)}
                    size="large"
                  />
                </div>
              </div>
              <div className="pt-6">
                <div className="flex">
                  <span className="pr-2">XII.</span>
                  {activeTab === 1 ? (
                    <p>
                      I understand if I sell the phone before paying the full
                      amount, this is a Criminal Offence and reported to Police
                    </p>
                  ) : (
                    <p>
                      Ndabyumva, ni ngurisha terefone mbere yo kwishyura
                      amafaranga yose, iki ni icyaha gihanirwa kandi
                      kikamenyeshwa kuri police
                    </p>
                  )}
                </div>
                <div className="pt-4 pl-4">
                  <Radio.Group
                    options={activeTab === 1 ? plainOptions : plainOptionsKiny}
                    onChange={(e: any) => setQuestion12(e.target.value)}
                    size="large"
                  />
                </div>
              </div>
            </section>
          </div>

          <div>
            <h1 className="text-lg font-bold capitalize py-5 ">
              {`${
                activeTab === 1
                  ? "Copy of National Id"
                  : "Fotokopi y'Indangamuntu"
              } `}
            </h1>
            <div className="rounded-md">
              <img
                src={
                  wareHouse?.createdCart?.data?.customer?.subscriptionInfo
                    ?.nationalIDDoc
                }
                alt="National Id"
                className="object-cover w-[320px] h-32 rounded-md"
              />
            </div>
          </div>
        </div>
        <div className="mt-8 ml-3">
          <div className="py-10">
            <Checkbox
              checked={checked}
              onChange={onChange}
              disabled={!checkAcceptTerms()}
            >
              {`${activeTab === 1 ? "I agree with " : "Nemeye "}`}
              <span className="text-lg">
                {`${
                  activeTab === 1
                    ? " Terms and Conditions."
                    : " Amategeko n'amabwiriza."
                }`}
              </span>
            </Checkbox>
          </div>
          {checked && checkAcceptTerms() && (
            <div className="min-h-52 w-[22rem] ">
              <SignatureCanvas
                canvasProps={{
                  className:
                    "mt-4 h-[153px] w-[352px] border-dotted border-2 border-indigo-300 px-3 py-2 cursor-pointer",
                }}
                ref={(data: any) => setSign(data)}
                penColor="black"
              />
              <SignatureButton
                handleClear={handleClear}
                checkSignature={checkSignature}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default YellowTermsAndConditions;
