import { LoadingButton } from "@mui/lab";
import UploadComponent from "../../forms/Upload";
import { Input } from "antd";
import { useSelector } from "react-redux";

const UploadEBMData = (props: any) => {
  const { wareHouse, posYelllow } = useSelector((state: any) => state);

  return (
    <div className="relative">
      <div className="flex flex-col">
        <h1 className="font-extrabold pb-6 text-[20px] leading-relaxed text-center">
          Upload IBM Receipt
        </h1>
        <div className="pb-6 mx-10 capitalize">
          <h1 className="font-extrabold text-[17px] leading-relaxed pb-4">
            Customer
          </h1>
          <div className="grid grid-cols-2">
            <div>
              <p className="font-medium text-[16px] leading-relaxed">
                Names:{" "}
                <span className="font-medium text-[14px] leading-relaxed pb-4 pl-1">
                  {props?.selectedCart?.customer?.name}
                </span>
              </p>
              <p className="font-medium text-[16px] leading-relaxed">
                Phone:{" "}
                <span className="font-medium text-[14px] leading-relaxed pb-4 pl-2">
                  {`+${props?.selectedCart?.customer?.phone}`}
                </span>
              </p>
              <p className="font-medium text-[16px] leading-relaxed">
                ID:{" "}
                <span className="font-medium text-[14px] leading-relaxed pb-4 pl-10">
                  {props?.selectedCart?.customer?.nationalId}
                </span>
              </p>
            </div>
            <span className="ml-16">
              <UploadComponent
                setPictures={props?.setPictures}
                isCard={"picture-card"}
                limit={1}
                // default={
                //   props?.data && [
                //     {
                //       status: "done",
                //       url: props?.data?.logo,
                //     },
                //   ]
                // }
              />
            </span>
          </div>
          <div className="w-60">
            <label className="font-medium text-[16px] leading-relaxed">
              Invoice Number
            </label>
            <Input
              type="number"
              onChange={(e: any) => props?.setInvoiceNumber(e.target.value)}
            />
          </div>
        </div>
      </div>
      <span className="pl-8 space-x-4 ml-2.5">
        <LoadingButton
          variant="outlined"
          onClick={props?.onCancel}
          style={{ width: 100 }}
        >
          Back
        </LoadingButton>
        <LoadingButton
          loading={posYelllow?.isFetching || wareHouse?.isFetching}
          style={{ width: 120 }}
          variant="contained"
          onClick={props?.handleSendIBMReceipt}
        >
          Submit
        </LoadingButton>
      </span>
    </div>
  );
};

export default UploadEBMData;
