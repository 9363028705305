import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";

const SignatureButton = (props: any) => {
  const { wareHouse, pos } = useSelector((state: any) => state);
  return (
    <div className=" float-right mt-4 printBtn ">
      <LoadingButton
        sx={{
          backgroundColor: "rgb(229 231 235)",
          color: "black",
          padding: "5px 25px",
          marginRight: "10px",
        }}
        onClick={() => props?.handleClear()}
        className="px-8 py-1 rounded-md bg-gray-200 m-2"
      >
        Clear
      </LoadingButton>
      <LoadingButton
        style={{ backgroundColor: "blue", color: "white" }}
        sx={{
          backgroundColor: wareHouse?.isFetching ? "gray" : "blue",
          color: "white",
          padding: "5px 25px",
        }}
        onClick={() => {
          props?.checkSignature();
        }}
        disabled={wareHouse?.isFetching || pos?.isFetching}
        loading={wareHouse?.isFetching || pos?.isFetching}
      >
        Save
      </LoadingButton>
    </div>
  );
};

export default SignatureButton;
