import { notification } from "antd";
import { myPosYelloActions } from ".";
import {
  createConsentService,
  getAllConsentService,
  updateConsentService,
  getOneConsentService,
  createApplicationService,
  sendContractService,
  uploadDeviceToYellowTenantService,
  paymentRequestService,
  replaceDeviceToYellowService,
  uploadEBMToYellowService,
} from "./services";

export const createConsentAction = (token: string, data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosYelloActions.setIsFetching(true));
      const res = await createConsentService(data, token);
      if (res?.status === 201) {
        dispatch(myPosYelloActions.setIsFetching(false));
      } else if (res?.response?.data?.status === 400) {
        dispatch(myPosYelloActions.setIsFetching(false));
        notification.info({ message: res?.response?.data?.error });
        return false;
      }
      dispatch(myPosYelloActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const makePaymentAction = (token: string, data: {}, itemId: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosYelloActions.setIsFetching(true));
      const res = await paymentRequestService(data, token, itemId);
      if (res?.status === 201) {
        dispatch(myPosYelloActions.setIsFetching(false));
        return true;
      } else if (res?.response?.data?.status === 400) {
        dispatch(myPosYelloActions.setIsFetching(false));
        notification.info({ message: res?.response?.data?.error });
        return false;
      }
      dispatch(myPosYelloActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllConsentAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosYelloActions.setIsFetching(true));
      const res = await getAllConsentService(token, query);
      if (res?.status === 200) {
        dispatch(myPosYelloActions.setAll(res));
        dispatch(myPosYelloActions.setIsFetching(false));
      }
      dispatch(myPosYelloActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneConsentAction = (token: string, itemId: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosYelloActions.setIsFetching(true));
      const res = await getOneConsentService(token, itemId);

      if (res?.status === 200) {
        dispatch(myPosYelloActions.setSelected(res));
        dispatch(myPosYelloActions.setIsFetching(false));
      }
      dispatch(myPosYelloActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateConsentAction = (
  token: string,
  itemId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosYelloActions.setIsFetching(true));
      const res = await updateConsentService(token, itemId, data);
      if (res?.status === 200) {
        const resUpdated = await getOneConsentService(token, itemId);
        dispatch(myPosYelloActions.setSelected(resUpdated));
        dispatch(myPosYelloActions.setIsFetching(false));
      }
      dispatch(myPosYelloActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const createApplicationAction = (token: string, data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosYelloActions.setIsFetching(true));
      const res = await createApplicationService(data, token);
      if (res?.status === 200) {
        notification.success({ message: "Request Sent Successfully" });
        dispatch(myPosYelloActions.setIsFetching(false));
        return true;
      } else if (res?.response?.data?.status === 400) {
        dispatch(myPosYelloActions.setIsFetching(false));
        notification.info({ message: res?.response?.data?.error });
        return false;
      } else {
        notification.error({
          message: "Something went wrong... Try again or ask for help",
        });
      }
      dispatch(myPosYelloActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const sendContractAction = (token: string, data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosYelloActions.setIsFetching(true));
      const res = await sendContractService(data, token);
      if (res?.status === 201) {
        dispatch(myPosYelloActions.setIsFetching(false));
        notification.success({ message: "Contract Submitted Successfully" });
      } else if (res?.response?.data?.status === 400) {
        dispatch(myPosYelloActions.setIsFetching(false));
        notification.info({ message: res?.response?.data?.error });
        return false;
      }
      dispatch(myPosYelloActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const saveSignatureAndlegaLigalAgreementAction = (
  token: string,
  itemId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosYelloActions.setIsFetching(true));
      const res = await updateConsentService(token, itemId, data);
      if (res?.status === 200) {
        dispatch(myPosYelloActions.setIsFetching(false));
      } else if (res?.response?.data?.status === 400) {
        dispatch(myPosYelloActions.setIsFetching(false));
        notification.info({ message: res?.response?.data?.error });
        return false;
      }
      dispatch(myPosYelloActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const uploadDeviceToYellowTenantAction = (
  token: string,
  data: {},
  itemId: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosYelloActions.setIsFetching(true));
      const res = await uploadDeviceToYellowTenantService(data, token, itemId);
      if (res?.status === 200) {
        dispatch(myPosYelloActions.setIsFetching(false));
        notification.success({ message: "Device Verified Successfully" });
        return true;
      } else if (res?.response?.data?.status === 400) {
        dispatch(myPosYelloActions.setIsFetching(false));
        notification.info({ message: res?.response?.data?.error });
        return false;
      }
      dispatch(myPosYelloActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const replaceDeviceToYellowAction = (token: string, data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosYelloActions.setIsFetching(true));
      const res = await replaceDeviceToYellowService(data, token);
      if (res?.status === 201) {
        dispatch(myPosYelloActions.setIsFetching(false));
        notification.success({ message: "Request Sent Successfully" });
        return true;
      } else if (res?.response?.data?.status === 400) {
        dispatch(myPosYelloActions.setIsFetching(false));
        notification.info({ message: res?.response?.data?.error });
        return false;
      }
      dispatch(myPosYelloActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const uploadEBMToYellowAction = (
  token: string,
  saleId: string,
  data: {}
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosYelloActions.setIsFetching(true));
      const res = await uploadEBMToYellowService(data, token, saleId);
      if (res?.status === 201) {
        dispatch(myPosYelloActions.setIsFetching(false));
        notification.success({ message: "Sent Successfully" });
        return true;
      } else if (res?.response?.data?.status === 400) {
        dispatch(myPosYelloActions.setIsFetching(false));
        notification.info({ message: res?.response?.data?.error });
        return false;
      }
      dispatch(myPosYelloActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
