import React from "react";
import { Modal } from "antd";

const YellowModel: React.FC<{
  open?: any;
  handleOpen?: any;
  content?: any;
  onCancel?: any;
  width?: number;
}> = ({ open, onCancel, content, width }) => {
  return (
    <Modal footer={null} open={open} onCancel={onCancel} width={width}>
      {content}
    </Modal>
  );
};

export default YellowModel;
